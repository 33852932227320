import React from "react";
import "../styles/Old_Pricing.scss";
import { NavLink } from "react-router-dom";
import googlePlayImage from "../../../images/google.png";
import appStoreImage from "../../../images/app.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const pricingOptions = [
  {
    title: "Peer Practice",
    options: ["LeaderBridge Monthly - $16", "LeaderBridge Yearly - $96"],
    description:
      "Get access to peer support and practice tools (web and downloadable options) ",
    price: "$16/mo or $96/yr",
    buttonLabel: "Join Now",
    webDownloadLink: "https://www.leaderbridge.com/",
  },
  {
    _id: "6690f69faf4d953440320dfd",
    title: "PhD Expert Support",
    options: [
      "Profile Calibration Meeting (includes 1 year of LeaderBridge access) - $384",
      "PhD Leadership Assessment (includes 1 year of LeaderBridge access) - $960",
    ],
    description: "Expert support with a focus on leadership development.",
    price: "$384 and $960",
    buttonLabel: "Get Started",
    webDownloadLink: "https://www.leaderbridge.com/",
  },
  {
    title: "Customized Coaching and Assessment",
    options: [
      "3- and 6-Month Leadership Coaching - Prices Vary",
      "Deeper-Dive Assessment Battery - Prices Vary",
    ],
    description: "Tailored coaching and comprehensive assessments.",
    price: "Prices Vary",
    buttonLabel: "Book a Meeting",
    webDownloadLink: "https://www.leaderbridge.com/",
  },
];

function Old_Pricing() {
  const history = useHistory();
  return (
    <div className="pricing-table">
      <div className="pricing-cards">
        {pricingOptions.map((option, index) => (
          <div
            key={index}
            className={`pricing-card ${index === 0 ? "active" : ""}`}
          >
            <h2 className="card-title">{option.title}</h2>
            <p className="card-description">{option.description}</p>
            <h3 className="card-price">{option.price}</h3>
            <ul className="card-options">
              {option.options.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>

            <button
              className="card-button"
              onClick={() => {
                if (option.price === "Prices Vary") {
                  history.push({
                    pathname: `/contactForm`,
                  });
                } else if (option.price) {
                  history.push({
                    pathname: `/payment`,
                    state: {
                      payment: {
                        _id: "6690f69faf4d953440320dfd",
                        title: "PhD Expert Support",
                        options: [
                          "Profile Calibration Meeting (includes 1 year of LeaderBridge access) - $384",
                          "PhD Leadership Assessment (includes 1 year of LeaderBridge access) - $960",
                        ],
                        description:
                          "Expert support with a focus on leadership development.",
                        price: 384,
                        buttonLabel: "Get Started",
                        webDownloadLink: "https://www.leaderbridge.com/",
                      },
                    },
                  });
                }
              }}
            >
              {option.buttonLabel}
            </button>
          </div>
        ))}
      </div>
      <div className="footer-links">
        <NavLink to="/contactForm">Need Help? Contact Support.</NavLink>
      </div>
      <div className="store-links">
        <a href="https://www.apple.com/app-store/">
          <img src={appStoreImage} alt="App Store" />
        </a>
        <a href="https://play.google.com/store">
          <img src={googlePlayImage} alt="Google Play Store" />
        </a>
      </div>
    </div>
  );
}

export default Old_Pricing;
