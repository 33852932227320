// React imports start...
// React imports end...

// Dev imports start...
import { ApiPost, ApiGetNoAuth } from "../ApiData";
import { buildSuccessToastCard, buildFailureToastCard } from "../../showUserFeedbackMessages/reactToastCardBuilder";
// Dev imports end...



export const fetchAllWebinarsHandler = async () => {

    try {
        const fetchedAllWebinars = await ApiGetNoAuth(`webinar/all-webinars`);
        if (fetchedAllWebinars?.status === 200) {
            return fetchedAllWebinars;
        }
    } catch (err) {
        buildFailureToastCard(`Some error occured in fetching all webinars`);
        return null;
    }
    
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchWebinarById = async ({ _id }) => {

    try {
        const fetchedWebinarById = await ApiGetNoAuth(`webinar/webinar-details/${_id}`);
        if (fetchedWebinarById?.status === 200) {
            return fetchedWebinarById;
        }
    } catch (err) {
        buildFailureToastCard(`Some error occured in fetching the webinar details`);
        return null;
    }

    
}


