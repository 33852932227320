import { API } from "../../config/api.config";
import Auth from "../auth";
import axios from "axios";

export const BaseURL = API.hostUrl;
export const FullyVerifed = API.FullyVerifed;

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
};

export const ApiPostNoAuth = (type, userData, platformBaseUrl) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${platformBaseUrl ? platformBaseUrl : BaseURL}${type}`,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPostNoAuthVerify = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        FullyVerifed + type,
        userData,
        getVerifyHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPostVerify = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(FullyVerifed + type, userData, getVerifyHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPutNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BaseURL + type,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BaseURL + type,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const Api = (type, methodtype, userData) => {
  return new Promise((resolve, reject) => {
    userData = userData || {};
    axios({
      url: BaseURL + type,
      headers: getHttpOptions(),
      data: userData,
      type: methodtype,
    })
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPost = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiDelete = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiDownload = (type, userData) => {
  let method = userData && Object.keys(userData).length > 0 ? "POST" : "GET";
  return new Promise((resolve, reject) => {
    axios({
      url: BaseURL + type,
      method,
      headers: getHttpOptions(),
      data: userData,
    })
      .then((res) => res.blob())
      .then((res) => resolve(res))
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGetBuffer = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.buffer();
        } else {
          resolve(null);
        }
      })
      .then((buffer) => {
        resolve(buffer);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = `Bearer ${Auth.getToken()}`;
  }

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["x-auth-token"] = Auth.getToken();
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};

export const CalendlyGetNoAuth = (type, userData, platform) => {
  return new Promise((resolve, reject) => {
    axios
      .get(type, getHttpOptionsCalendly())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const getHttpOptionsCalendly = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers[
      "Authorization"
    ] = `Bearer ${"eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE3MDA3Mzc3LCJqdGkiOiJkMDA4ZDQ2YS02M2YyLTQ4NGMtODZkYy1jYzVkODlhYTBiYmEiLCJ1c2VyX3V1aWQiOiIzZTU0ZDA0OC0wZjY4LTQwNGQtYWJjYy0wYmY4MzcxZmQ3NjQifQ.8seewvEs4xis68pTEWBXDUPdn4bt7LyanP5eA8jq1-sjGe1xQLV9lML60LGSci857DTN15Nbu0njLFCzZbaBqg"}`;
  }

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["x-auth-token"] =
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE3MDA3Mzc3LCJqdGkiOiJkMDA4ZDQ2YS02M2YyLTQ4NGMtODZkYy1jYzVkODlhYTBiYmEiLCJ1c2VyX3V1aWQiOiIzZTU0ZDA0OC0wZjY4LTQwNGQtYWJjYy0wYmY4MzcxZmQ3NjQifQ.8seewvEs4xis68pTEWBXDUPdn4bt7LyanP5eA8jq1-sjGe1xQLV9lML60LGSci857DTN15Nbu0njLFCzZbaBqg";
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};

export const getVerifyHttpOptions = (options = defaultHeaders) => {
  let headers = {};
  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = `Bearer ${Auth?.getVerifyToken()}`;
  }

  // if (options.hasOwnProperty("isAuth") && options.isAuth) {
  //   headers["x-auth-token"] = Auth.getVerifyToken();
  // }

  // if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
  //   headers["Content-Type"] = "application/json";
  // }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};
