import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Image = ({
    src
}) => {
    return (
        <img 
            src={src} 
            onDragStart={(e) => {
                e.preventDefault();
            }}
            role='presentation' 
        />
    )   
}

const PerformanceCarousel = () => {

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 6 },
    };

    const items = [
        <img width={200} height={200} src="assets/images/federal-reserve-system.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/united-airlines.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/cuna.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/baxter.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/sc-johnson.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/cbc.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/swiss-air.png" onDragStart={handleDragStart} role="presentation" />,
        <img width={200} height={200} src="assets/images/amazon.png" onDragStart={handleDragStart} role="presentation" />,
    
    ];

    return (
        <>
            <AliceCarousel 
                mouseTracking
                items={items}
                autoPlay={true}
                infinite={true}
                responsive={responsive}
                disableButtonsControls={true}
                disableDotsControls={true}
                animationDuration={1000}
                autoPlayInterval={4000}
            />
        </>
    )
}

export default PerformanceCarousel;