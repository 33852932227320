import React, { useState } from "react";

// 3rd party packages start...
import Loader from "react-loader-spinner";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Form from "../Form/Form";
// 3rd party packages end...

// Dev imports start...
import { ApiPostNoAuth } from "../../../utils/Api/ApiData";
import {
  buildSuccessToastCard,
  buildFailureToastCard,
} from "../../../utils/showUserFeedbackMessages/reactToastCardBuilder";
// Dev imports start...

import "./GeneralFormComponent.css";
import { useHistory } from "react-router-dom";
// Child Components...
const MUIInput = ({ fieldName }) => {
  return (
    <>
      <TextField
        fullWidth
        id="standard-basic"
        label={fieldName}
        variant="standard"
      />
    </>
  );
};
// Child Components...

const GeneralFormComponent = ({
  backdropFormHeading,
  setShowFormState,
  formState,
  updateFormStateHandler,
  checkFormValidationHandler,
  mapFormStateToBackendRequestPayloadHandler,
  clearFormStateHandler,
  InvitationFormLinkContainer,
  webinarDetails,
  _id,
  styles,
}) => {
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const history = useHistory();
  // All handlers start...
  // Submit form to backend start...
  const submitFormHandler = async () => {
    const payload = mapFormStateToBackendRequestPayloadHandler();
    console.log("payloadpayload", payload);
    if (payload) {
      setFormSubmitLoading(true);
      const req = await ApiPostNoAuth(`webinar/invitation`, payload);
      if (req?.status === 200) {
        buildSuccessToastCard(req?.data?.message);

        history.push({
          pathname: "/thanks-for-participant",
          state: {
            webinarDetails,
            ...payload,
          },
        });
      } else {
        buildFailureToastCard(req?.data?.message);
      }

      setFormSubmitLoading(false);
      clearFormStateHandler();
    } else {
      buildFailureToastCard(`Please enter valid input values`);
    }
  };
  // Submit form to backend end...
  // All handlers end...

  return (
    <>
      <div
        className={`general-form-container`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h3 className={`general-form-heading`}>{backdropFormHeading}</h3>

        <Form
          setShowFormState={setShowFormState}
          formState={formState}
          submitFormHandler={submitFormHandler}
          formSubmitLoading={formSubmitLoading}
          updateFormStateHandler={updateFormStateHandler}
          checkFormValidationHandler={checkFormValidationHandler}
          InvitationFormLinkContainer={InvitationFormLinkContainer}
          styles={styles}
          _id={_id}
        />
      </div>
    </>
  );
};

export default GeneralFormComponent;
