import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "./OTPVerification.scss";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ApiPutNoAuth } from "../../utils/Api/ApiData";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Auth from "../../utils/auth";

const OTPVerification = () => {
  const location = useLocation();
  let history = useHistory();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state in seconds
  const [timerInterval, setTimerInterval] = useState(null);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (!location?.state?.email) {
      toast.error("Email not provided, redirecting back.", {
        theme: "colored",
      });
      history.goBack();
    }
  }, [location, history]);

  // Function to start the resend process
  const handleResend = () => {
    setResendLoading(true);
    setTimer(900); // 900 seconds = 15 minutes
    startTimer();

    let data = { email: location?.state?.email };
    ApiPutNoAuth("user/resend-code", data)
      .then((res) => {
        toast.success(res.data.message, { theme: "colored" });
      })
      .catch((err) => {
        console.log("Resend failed", err);
        toast.error("Resend failed, please try again", { theme: "colored" });
      })
      .finally(() => {
        setResendLoading(false);
      });
  };

  // Function to start the countdown timer
  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setTimerInterval(null);
        }
        return prevTimer > 0 ? prevTimer - 1 : 0;
      });
    }, 1000);

    setTimerInterval(interval);
  };

  const verifyOTPCode = () => {
    setLoading(true);
    let data = { email: location?.state?.email, otp };
    ApiPutNoAuth("user/verify-code", data)
      .then((res) => {
        toast.success(res.data.message, { theme: "colored" });
        Auth.setAuthToken(res?.data?.payload.token);
        history.push({
          pathname: "/Feature_Pricing",
        });
      })
      .catch((err) => {
        console.log("Verification failed", err);
        toast.error("Verification failed, please try again", {
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="otp-verification">
      <div className="otp-container">
        <h2>Please enter the One-Time Code to verify your account</h2>
        <p>A One-Time Code has been sent to your email for confirmation</p>
        <div className="otp-inputs">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <button
          className="validate-button"
          onClick={verifyOTPCode}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin"></i> : "Validate"}
        </button>
        <div className="resend">
          <a onClick={handleResend} disabled={resendLoading}>
            {resendLoading ? "Resending..." : "Resend One-Time Code"}
          </a>

          {timer === 0 && (
            <p>Your OTP has expired. Please resend to receive a new one.</p>
          )}
        </div>
      </div>
    </div>
  );
};

OTPVerification.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default OTPVerification;
