import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ForgotPassword.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import { ApiPut } from "../../utils/Api/ApiData";

const ForgotPassword = () => {
  const history = useHistory();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    document.title = "Reset Password | leadership.NET";
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please Enter Email!"),
  });

  const handleSubmit = (values) => {
    setloader(true);
    ApiPut(`user/forget`, { email: values.email })
      .then((res) => {
        if (res.data.result === 0) {
          history.push("/login");
          setloader(false);
          toast.success(res?.data?.message, { theme: "colored" });
        } else {
          setloader(false);
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setloader(false);
        toast.error(err, { theme: "colored" });
      });
  };

  return (
    <section className="forgot-password-align">
      <ToastContainer />
      <div className="container align-center">
        <div className="grid">
          <div className="grid-items">
            <div className="title-text-style">
              <h3>Forgot Password?</h3>
              <p>
                No issues just enter the registered email and you will receive
                the link to reset your password in your inbox.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    placeholder="jennadavis@gmail.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error-message"
                  />
                  <div className="send-button-center">
                    <button type="submit" disabled={isSubmitting}>
                      <span style={{ color: "white" }}>
                        {loader ? "" : "Send mail"}
                      </span>
                      <Loader
                        type="Oval"
                        color="#FFF"
                        height={25}
                        width={25}
                        visible={loader}
                      />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
