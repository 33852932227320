// React imports start...
import React from 'react';
// React imports end...

// 3rd party packages start...
import TextField from '@mui/material/TextField';
import Loader from "react-loader-spinner";
// 3rd party packages end...


// Css imports start...
import "./Form.css";
// Css imports end...

const Form = ({
    setShowFormState,
    formState,
    submitFormHandler,
    formSubmitLoading,
    updateFormStateHandler,
    checkFormValidationHandler,
    InvitationFormLinkContainer,
    _id,
    styles
}) => {
    return (
        <>
            <form 
                className={`form`}
                style={{
                    ...styles
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    submitFormHandler();
                }}    
            >

                {formState?.map((field, fieldIdx) => {
                    return (
                        <React.Fragment
                            key={fieldIdx}
                        >
                            <TextField 
                                fullWidth 
                                id="standard-basic"
                                value={field?.value}
                                onChange={(e) => {
                                    updateFormStateHandler(field?.fieldName, e.target.value)
                                }}
                                label={`${field?.fieldName} ${field?.required ? `*` : ``}`}
                                InputLabelProps={{ 
                                    shrink: true,
                                    style: {
                                        fontSize: '1.5rem',
                                        fontWeight: '300',
                                        fontFamily: 'Nunito, sans-serif'
                                    }
                                }}
                                variant="standard" 
                                helperText={field?.showErrorMessage ? field?.errorMessage : ``}
                                error={Boolean(field?.showErrorMessage)}
                            />
                        </React.Fragment>
                    )
                })}

                <InvitationFormLinkContainer
                    _id={_id}
                />

                <div
                    className={`d-flex justify-content-center gap-3`}
                >
                    <button 
                        className='btn-primary-custom-reverse'
                        onClick={() => {
                            setShowFormState(false);
                        }}
                        type='submit'
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={() => {
                        }}
                        disabled={!checkFormValidationHandler(formState)}
                        className={`btn-primary-custom ${!checkFormValidationHandler(formState) ? `btn-primary-custom-disabled` : ``}`}
                        type='submit'
                    >
                        <Loader
                            type="Oval"
                            color="#F6FBFF"
                            height={25}
                            width={25}
                            visible={formSubmitLoading}
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0px 7px",
                            }}
                        />
                        {formSubmitLoading ? "" : `Submit`} 
                    </button>
                </div>
            </form>
        </>
    )
}

export default Form;