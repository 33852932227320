// src/components/ProtectedRoute.js
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/auth";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKeyEnvironmentHandler } from "../utils/environmentConfigurationHandlers";
import { Elements } from "@stripe/react-stripe-js";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let token = Auth.getToken();

  const stripePromise = loadStripe(
    stripeKeyEnvironmentHandler({
      liveKey: process.env.REACT_APP_PK_LIVE,
      testKey: process.env.REACT_APP_PK_TEST,
    })
  );

  return (
    <Elements stripe={stripePromise}>
      <Route
        {...rest}
        render={(props) =>
          token ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    </Elements>
  );
};

export default ProtectedRoute;
