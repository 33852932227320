import React, { useState } from "react";
import "./Hero.css";
import Scroller from "./Scroller/Scroller";
// import Ln_logo from "./Ln_logo/Ln_logo";
import LN_Logo from "../../src/images/lN_LOGO.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import SubHeader from "./subHeader/subHeader";

const Hero = () => {
  const [open, setOpen] = useState(true);
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  const remover = () => {
    setOpen((prev) => !prev);
  };

  const history = useHistory();

  return (
    <>
      {/* <SubHeader /> */}
      <div id="home">
        {open && (
          <div className="notification-bar">
            <div className="notification-content">
              <div
                className="for-teams"
                onClick={() => handleRedirect("https://leaderbridge.com/")}
              >
                FOR PEERS
              </div>
              <div
                className="logo"
                onClick={() => handleRedirect("https://leaderbridge.com/")}
              >
                <img src="/assets/images/LeaderBridge-logo.png" alt="Logo" />
                <span style={{ fontWeight: "800" }}>:</span>
                {/* <span className="principles-us">PrinciplesUs</span> */}
              </div>
              {/* <div className="notification-text">
                Grow skills together using our anonymous peer practice portal
              </div> */}

              <div className="notification-text">
                Peers practicing leadership
              </div>
              <div
                className="learn-more"
                onClick={() => handleRedirect("https://leaderbridge.com/about")}
              >
                Learn More
                {/* <span>&#8250;</span> */}
              </div>
              {/* <button className="close-btn" onClick={() => setOpen(false)}>
                &times;
              </button> */}
            </div>
            {/* <div className="notification-text">
                Grow skills together using our anonymous peer practice portal
              </div> */}
          </div>
        )}

        <div className="principles-you">
          <div className="new-bg-images">
            <main className="principles-main">
              <div className="left-content">
                <div className="let-ln-logo-container">
                  <div className="brand-logo">
                    <img src={LN_Logo} alt="Logo" />
                    <div className="leader-ship">
                      <span className="leader">leadership.</span>
                      <span className="ship">NET</span>
                      <span className="network">
                        : A network of people who practice leadership
                      </span>
                    </div>
                  </div>
                </div>
                <div className="let-ln-logo-container">{/* <Ln_logo /> */}</div>{" "}
                <div className="change-the-text">
                  {/* <span class="doer-font">Practice with Peers.</span> <br />
                  <span class="doer-font" sty>
                    Practice with a PhD expert.
                  </span>{" "}
                  <br />
                  <span className="doer-font">Help others. Help yourself.</span> */}
                  <br />
                  <button
                    className="assessment-button"
                    onClick={() =>
                      handleRedirect(
                        "https://leaderbridge.com/assessmentRanking?data=ranking1"
                      )
                    }
                  >
                    <span>
                      Take the Free Leadership Context Profile (10 mins)
                    </span>
                  </button>
                  <div className="profile-link">
                    <div className="create-profile-line">
                      <span
                        className="link-tag"
                        onClick={() => history.push("/signup")}
                      >
                        CREATE A PROFILE
                      </span>
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        TO SAVE YOUR PROGRESS.
                      </span>
                    </div>
                    <div className="login-line">
                      <span>ALREADY HAVE AN ACCOUNT? </span>{" "}
                      <span
                        className="link-tag"
                        onClick={() => history.push("/login")}
                      >
                        {" "}
                        LOG IN
                      </span>
                    </div>
                  </div>
                </div>
                <div class="export-assessment-box">
                  <span class="export-text">
                    Expert Assessment and Guidance <br /> Peer-to-Peer Practice
                  </span>
                </div>
              </div>
              <div className="right-graphic">
                {/* <p className="heading">
                  A network of people who practice leadership
                </p> */}
                <img src="/assets/images/new-pepops.png" alt="Logo" />
                {/* Replace with actual image path or graphic component */}
                {/* <img src="/path-to-graphic.png" alt="Right Graphic" /> */}
              </div>
            </main>

            {/* <img className="bottom-image" src="/assets/images/three.png" /> */}
          </div>
          <div className="both-box-container">
            {/* <span className="Expert-Assessment">
              Expert Assessment and Guidance Peer-to-peer Practice
            </span> */}

            <div className="box-main-container">
              {" "}
              <div
                className="box-container"
                // style={{ backgroundColor: "rgb(42 69 111)" }}
              >
                <div className="box">
                  <h4></h4>
                  <h6>
                    <span class="doer-r-s">Basic leadership framework</span>{" "}
                  </h6>
                  <blockquote className="second-box">
                    After completing over 4,000 leadership development
                    assignments, the leadership.NET team have developed a basic
                    leadership optimization framework called DOER-R
                    <span className="second-box-new">&#169;</span> (Define, Own,
                    Enlist, Resolve, Reinforce).
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="box-main-container">
              {" "}
              <div className="box-container">
                <div className="box">
                  <blockquote className="second-box">
                    "We believe leadership is fundamental and that all of us are
                    required to lead at some point. At Leadership.NET, we're
                    dedicated to helping you practice your leadership so that
                    when you are called to lead, you will be ready."
                  </blockquote>

                  <h6 className="John-Behr">
                    — John Behr, PhD, I/O Psychologist
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Scroller />
        <section id="page1">
          <div className="page-container">
            <div className="page-row">
              <div className="page-box quote-box">
                <div className="quote">
                  <h2>
                    <span>“</span>Leadership is an experience everyone can
                    share, not just those at the top.<span>”</span>
                  </h2>
                  <h4>— John Behr, PhD, I/O Psychologist</h4>
                  {/* <p>Creator of LeaderBridge</p> */}
                </div>
              </div>
              {/* <div className="page-box service-box">
                <div className="services">
                  <h1>Our Services</h1>
                  <div className="service">
                    <h3>HI-SD</h3>
                    <p>
                      Our "High Impact-Short Duration" leadership accelerator
                      enhances focus, fosters collaboration, and expedites the
                      resolution of crucial issues.
                    </p>
                  </div>
                  <div className="service">
                    <h3>EXECUTIVE ASSESSMENT</h3>
                    <p>
                      Interviewing, testing, and expert analysis are applied to
                      produce a customized, accurate, and highly reliable report
                      of key developmental opportunities.
                    </p>
                  </div>
                  <div className="service">
                    <h3>EXECUTIVE COACHING</h3>
                    <p>
                      Tailored to executives. Typically three to six months.
                      Higher level of support and guidance. Often begins with
                      Executive Assessment.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
