import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import { ApiPost, ApiPostNoAuth } from "../../utils/Api/ApiData";
import { API } from "../../config/api.config";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";

import "./contact.css";

export default function About() {
  const [inputValue, setinputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();
  const history = useHistory();

  /*const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (inputValue && !inputValue.name) {
      formIsValid = false;
      errors["name"] = "*Please Enter Name!";
    }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "*Please Enter email!";
    }

    if (inputValue && !inputValue.subject) {
      formIsValid = false;
      errors["subject"] = "*Please Select subject!";
    }

    if (inputValue && !inputValue.message) {
      formIsValid = false;
      errors["message"] = "*Please Enter message!";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    // if (name == "number") {
    // setinputValue({
    //   ...inputValue,
    //   [name]: value.replace(
    //     /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    //     ""
    //   ),
    // });

    // let val = e.target.value.replace(
    //   /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
    //   ""
    // );
    // setinputValue((prevState) => ({
    //   ...prevState,
    //   [name]: val,
    // }));
    // setinputValue({ ...inputValue, [name]: val });
    // setErrors({ ...errors, [name]: "" });
    // } else {
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // }
  };*/

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    // if (name == "number") {
    // setinputValue({
    //   ...inputValue,
    //   [name]: value.replace(
    //     /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    //     ""
    //   ),
    // });

    // let val = e.target.value.replace(
    //   /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
    //   ""
    // );
    // setinputValue((prevState) => ({
    //   ...prevState,
    //   [name]: val,
    // }));
    // setinputValue({ ...inputValue, [name]: val });
    // setErrors({ ...errors, [name]: "" });
    // } else {
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // }
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (inputValue && !inputValue.name) {
      formIsValid = false;
      errors["name"] = "*Please Enter Name!";
    }

    // if (inputValue && !inputValue.checkbox) {
    //   formIsValid = false;
    //   errors["checkbox"] = "*Please Enter checkbox!";
    // }

    if (inputValue && !inputValue.company) {
      formIsValid = false;
      errors["company"] = "*Please Enter company!";
    }

    if (
      inputValue.number &&
      !inputValue.number.match(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      )
    ) {
      formIsValid = false;
      errors["number"] = "Please Enter vaild number!";
    }

    // if (inputValue && !inputValue.number) {
    //   formIsValid = false;
    //   errors["number"] = "*Please Enter phone number!";
    // } else
    /*if (
      inputValue.number &&
      !inputValue.number.match(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      )
    ) {
      formIsValid = false;
      errors["number"] = "Please Enter vaild number!";
    } else if (inputValue?.number && !inputValue.contact_phone_code?.trim()) {
      formIsValid = false;
      errors["contact_phone_code"] =
        "Please enter country code and contact number";
    }*/

    // if (inputValue && !inputValue.information) {
    //   formIsValid = false;
    //   errors["information"] = "*Please Enter information!";
    // }

    // if (inputValue && !inputValue.extension) {
    //   formIsValid = false;
    //   errors["extension"] = "*Please Enter extension!";
    // }
    if (inputValue && !inputValue.email) {
      formIsValid = false;
      errors["email"] = "*Please Enter Email!";
    } else if (
      inputValue.email &&
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Please Enter vaild Email!";
    }
    if (inputValue && !inputValue.message) {
      // if (inputValue && !inputValue.subject) {
      //   formIsValid = false;
      //   errors["subject"] = "*Please Select subject!";
      // }

      formIsValid = false;
      errors["message"] = "*Please Enter message!";
    }

    setErrors(errors);
    return formIsValid;
  };

  const contactUS = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (validateForm()) {
      // if (!checkbox) {
      //   setSignupLoader(false);
      //   setchek("*Please Enter checkbox!");

      //   return;
      // }

      const body = {
        name: inputValue?.name,
        company: inputValue?.company,
        email: inputValue?.email,
        number: inputValue?.contact_phone_code + " " + inputValue?.number,
        //extension: inputValue?.extension,
        message: inputValue?.message,
        platform: "leadership.NET",
      };

      ApiPostNoAuth("contact/createcontact", body, API.hostUrlLeaderBridge)
        .then((res) => {
          // debugger;
          // document.getElementById("name").value = "";
          // document.getElementById("company").value = "";
          // document.getElementById("email").value = "";
          // document.getElementById("number").value = "";
          // document.getElementById("extension").value = "";
          setinputValue({
            name: "",
            company: "",
            email: "",
            contact_phone_code: "",
            number: "",
            //extension: "",
            message: "",
          });
          toast.success(res.data.message);

          setErrors({});
          // setinputValue({});

          history.push("/thank-you");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const MaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  return (
    <div>
      <ToastContainer />
      <>
        <section className="contact-form">
          <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>
            <form
              onSubmit={(e) => contactUS(e)}
              aria-labelledby="contact-title"
            >
              <div className="contact-row">
                <div className="contact-group">
                  <label htmlFor="name">Name</label>
                  <input
                    className="contact-input"
                    type="text"
                    id="name"
                    name="name"
                    value={inputValue.name}
                    placeholder="Jenna Davis"
                    onChange={(e) => handleOnChnage(e)}
                    required
                  />
                  <span className="contact-error" role="alert">
                    {errors["name"]}
                  </span>
                </div>
                <div className="contact-group">
                  <label htmlFor="email">Email</label>
                  <input
                    className="contact-input"
                    type="email"
                    id="email"
                    name="email"
                    value={inputValue.email}
                    placeholder="jennadavis@gmail.com"
                    onChange={(e) => handleOnChnage(e)}
                    required
                  />
                  <span className="contact-error" role="alert">
                    {errors["email"]}
                  </span>
                </div>
              </div>

              <div className="contact-row">
                <div className="contact-group">
                  <label htmlFor="company">Company</label>
                  <input
                    className="contact-input"
                    type="text"
                    id="company"
                    name="company"
                    value={inputValue.company}
                    placeholder="Company name"
                    onChange={(e) => handleOnChnage(e)}
                  />
                  <span className="contact-error" role="alert">
                    {errors["company"]}
                  </span>
                </div>
                <div className="contact-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    className="contact-input"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={inputValue.phone}
                    placeholder="Phone number"
                    onChange={(e) => handleOnChnage(e)}
                    required
                  />
                  <span className="contact-error" role="alert">
                    {errors["phone"]}
                  </span>
                </div>
              </div>
              <div className="contact-group">
                <label htmlFor="message">Message</label>
                <textarea
                  className="contact-textarea"
                  id="message"
                  name="message"
                  value={inputValue.message}
                  rows="4"
                  placeholder="Type your message here..."
                  onChange={(e) => handleOnChnage(e)}
                  required
                ></textarea>
                <span className="contact-error" role="alert">
                  {errors["message"]}
                </span>
              </div>
              <button type="submit" className="contact-submit">
                Submit
              </button>
            </form>
          </div>
        </section>
      </>
    </div>
  );
}
