// React imports start...
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// React imports end...

// Dev imports start...
import InvitationForm from "../../components/Webinar/InvitationForm/InvitationForm";
import {
  leadership,
  leaderbridge,
  appliedPsychologists,
  mappingFromBrandNamesToLegalBrandNames,
} from "../../utils/brandNames";
import { fetchWebinarById } from "../../utils/Api/ApiCalls/webinar";
import { navigation } from "../../utils/frontendNavigation/frontendNavigation";
// Dev imports end...

// Css imports start...
import "./WebinarDetails.css";
import { style } from "@mui/system";
// Css imports end...

const WebinarDetails = ({ showFeatures, webinarFeaturesRef }) => {
  const location = useLocation();

  // URL data start...
  const _id = location?.pathname
    ?.split(`${navigation?.webinarDetails}/`)[1]
    ?.toString();
  // URL data end...

  // State variables...
  const [webinarDetails, setWebinarDetails] = useState();
  const [showWebinarInvitationForm, setShowWebinarInvitationForm] =
    useState(false);
  const [formState, setFormState] = useState();
  // State variables...

  // All Handlers start...

  // State update handlers...
  const updateShowWebinarInvitationFormHandler = (value) => {
    setShowWebinarInvitationForm(value);
  };
  // State update handlers...

  const scrollToWebinarFeaturesHandler = () => {
    console.log(webinarFeaturesRef);
    const scrollTo =
      webinarFeaturesRef?.current?.getBoundingClientRect()?.top +
      window.scrollY;
    if (scrollTo) {
      window.scrollTo(0, Math.floor(scrollTo) - 300);
    }
  };
  // All Handlers end...

  // Effects start...
  useEffect(() => {
    document.title = "leadership.NET | Webinar Details";
  }, []);

  useEffect(() => {
    const asyncHandler = async () => {
      const fetchedWebinarById = await fetchWebinarById({ _id });

      if (fetchedWebinarById) {
        setWebinarDetails(
          fetchedWebinarById?.data?.payload?.fetchedWebinarById
        );
      }
    };

    asyncHandler();
  }, []);
  // Effects end...

  return (
    <section className="webinar-details text-center">
      {webinarDetails ? (
        <div className={`webinar-details-item webinar-details-item1`}>
          <div class="">
            <h3 class={`mb-3`}>
              <span class="font-w-4">
                {mappingFromBrandNamesToLegalBrandNames(webinarDetails?.app)}{" "}
                Webinar{" "}
              </span>
            </h3>
          </div>

          <div class="mb-5">
            <p class={`mb-1 fs-5`}>
              <span class="font-w-4 d-block">{webinarDetails?.title} </span>
            </p>
            {webinarDetails?.features?.map((feature, featureIdx) => {
              return (
                <React.Fragment key={featureIdx}>
                  <p className="mb-1 fs-5">- {feature?.description}</p>
                </React.Fragment>
              );
            })}
            {/* <p className='mb-1 fs-5'>- Discussion of tradeoffs of competency focused engagement</p>
                    <p className='mb-1 fs-5'>- Introduction to {leaderbridge} for focused engagement in problem solving</p> */}
            <p class={`mb-1 mt-5 fs-5`}>
              <span class="font-w-4 d-block">{`${webinarDetails?.date} `}</span>
            </p>
            <p class={`mb-1 fs-5`}>
              <span class="font-w-4 d-block">{`${webinarDetails?.time} `}</span>
            </p>
            <p class={`mb-1 fs-5`}>
              <span class="font-w-4 d-block">{`Approximately ${webinarDetails?.duration} Minutes `}</span>
            </p>
            <p class={`mb-1 fs-5`}>
              <span class="font-w-4 d-block">{`${webinarDetails?.platforms} (Webinar Link provided upon registration) `}</span>
            </p>
          </div>
        </div>
      ) : null}

      <div className={`webinar-details-item webinar-details-item2`}>
        <InvitationForm
          setShowFormState={setShowWebinarInvitationForm}
          formState={formState}
          setFormState={setFormState}
          webinarDetails={webinarDetails}
          _id={_id}
        />
      </div>
    </section>
  );
};

export default WebinarDetails;
