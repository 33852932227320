import React, { useEffect, useRef, useState } from "react";
import "./Header.scss"; // Ensure the SCSS is correctly linked
import Leadership from "./logos/Leadership.NET";
import { NavLink } from "react-router-dom";
import Auth from "../utils/auth";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const token = Auth.getToken();
  const dropdownRef = useRef(null);

  const handleScroll = () => {
    setSticky(window.pageYOffset > 0);
  };

  const clearStoreg = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const clearStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  return (
    <header className={`header ${isSticky ? "header--sticky" : ""}`}>
      <div className="header__container">
        <div className="header__brand">
          <NavLink to="/">
            <Leadership />
          </NavLink>
        </div>
        <nav className="header__nav">
          <a
            href="https://blog.leaderbridge.com"
            className="nav__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
          <NavLink to="/about" className="nav__item">
            About
          </NavLink>
          <a
            href="https://www.leaderbridge.com"
            className="nav__item"
            target="_blank"
            rel="noopener noreferrer"
          >
            Software
          </a>

          <NavLink to="/services" className="nav__item">
            Expert Guidance
          </NavLink>

          <NavLink to="/Feature_Pricing" className="nav__item">
            Features and Pricing
          </NavLink>
        </nav>
        {/* <div className="header__social-media">
          <a
          // href="https://www.linkedin.com/company/leader-bridge/"
          // target="_blank"
          // rel="noopener noreferrer"
          >
            <img
              src="/assets/images/LinedIn.svg"
              alt="LinkedIn Icon"
              className="social-icon"
            />
          </a>
          <a
          // href="https://instagram.com/leaderbridge1?igshid=YmMyMTA2M2Y="
          // target="_blank"
          // rel="noopener noreferrer"
          >
            <img
              src="/assets/images/insta.svg"
              alt="Instagram Icon"
              className="social-icon"
            />
          </a>
          <a
          // href="https://m.facebook.com/leaderbridge/?ref=py_c"
          // target="_blank"
          // rel="noopener noreferrer"
          >
            <img
              src="/assets/images/facebook.svg"
              alt="Facebook Icon"
              className="social-icon"
            />
          </a>
        </div> */}

        <div className="header__contact__container">
          <div className="header__contact">
            <a
              href="https://leaderbridge.com/assessmentRanking?data=ranking1"
              className="contact__button contact_us__button"
            >
              get started
            </a>
          </div>

          <div className="header__contact__border"></div>

          <div className="header__contact">
            {!token ? (
              <NavLink
                to="/login"
                className="contact__button get_started__button"
              >
                Login
              </NavLink>
            ) : (
              <div className="profile-container">
                <div class="dropdown">
                  {/* <img
                    src={"/assets/icon/profile.svg"}
                    alt="Profile Icon"
                    className="profile-icon"
                    data-bs-toggle="dropdown"
                  /> */}

                  <button data-bs-toggle="dropdown">
                    <div className="icon-button">
                      <span>LN</span>
                    </div>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </a>
                    </li>
                    <li onClick={clearStoreg}>
                      <a class="dropdown-item">Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
