import React from "react";
import PropTypes from "prop-types";
import "./UnscheduledMeetingItem.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UnscheduledMeetingItem = ({ meeting, paymentPlan }) => {
  const { name, start_time, end_time, status, event_memberships, location } =
    meeting?.resource || {};
  let history = useHistory();
  const startTime = start_time
    ? new Date(start_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "N/A";
  const startDate = start_time
    ? new Date(start_time).toLocaleDateString([], {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "N/A";

  const ownerName = event_memberships?.[0]?.user_name || "N/A";

  const handleJoinMeeting = (data) => {
    console.log("meeting?.plan?meeting?.plan?", data);
    history.push({
      pathname: "/accelerator",
      state: {
        planId: data?.plan?._id,
        myplanId: data?._id,
      },
    });
  };

  return (
    <div className="meeting-item">
      <div className="header">
        <h3 className="title">{meeting?.plan?.title}</h3>
        <p className={`status ${status?.toLowerCase()}`}>
          {status ? status.toUpperCase() : "UNSCHEDULED"}
        </p>
      </div>

      <div className="details">
        <p className="owner">Owner: {ownerName}</p>
        {!name && (
          <div className="static-details">
            <p>
              The user has completed the payment but not scheduled the meeting
              yet.
            </p>
            <p className="payment-plan">Payment Plan: {paymentPlan}</p>
          </div>
        )}

        <button
          className="join-button"
          onClick={() => handleJoinMeeting(meeting)}
        >
          Schedule Meeting
        </button>
      </div>
    </div>
  );
};

UnscheduledMeetingItem.propTypes = {
  meeting: PropTypes.shape({
    resource: PropTypes.shape({
      name: PropTypes.string,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      status: PropTypes.string,
      event_memberships: PropTypes.arrayOf(
        PropTypes.shape({
          user_name: PropTypes.string.isRequired,
        })
      ),
      location: PropTypes.shape({
        type: PropTypes.string,
        join_url: PropTypes.string,
      }),
    }),
    plan: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  paymentPlan: PropTypes.string.isRequired,
};

export default UnscheduledMeetingItem;
