import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ResetPassword.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiPost } from "../../utils/Api/ApiData";

const ResetPassword = () => {
  const history = useHistory();
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const pathName = window.location.pathname.split("/");
    setUserId(pathName[pathName.length - 1]);
  }, []);

  useEffect(() => {
    document.title = "Reset Password | leadership.NET";
  }, []);

  const initialValues = {
    password: "",
    reEnterPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password should be at least 8 characters long!")
      .required("Please Enter password!"),
    reEnterPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match!")
      .required("Please re-enter password!"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    ApiPost(`user/reset/id=${userId}`, { password: values.password })
      .then((res) => {
        setSubmitting(false);
        if (res.data.result === 0) {
          history.push("/login");
          toast.success(res?.data?.message, { theme: "colored" });
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
        resetForm();
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err, { theme: "colored" });
      });
  };

  return (
    <>
      <section className="forgot-password-align">
        <ToastContainer />
        <div className="container align-center">
          <div className="grid">
            <div className="grid-items">
              <div className="title-text-style">
                <h3>Reset Password</h3>
                <p>
                  We received your reset password request. Please enter your new
                  password!
                </p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="form-group">
                    <div>
                      <label htmlFor="password">Password</label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="*******"
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="error-message"
                      />
                    </div>
                    <div>
                      <label htmlFor="reEnterPassword">Re-enter Password</label>
                      <Field
                        type="password"
                        name="reEnterPassword"
                        placeholder="*******"
                      />
                      <ErrorMessage
                        name="reEnterPassword"
                        component="span"
                        className="error-message"
                      />
                    </div>
                    <div className="send-button-center">
                      <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Updating..." : "Update Password"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
