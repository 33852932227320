import React, { useEffect, useState } from "react";
import "./Payment.scss";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import CardInputs from "../CardInputs/CardInputs";
import { ApiGet, ApiPost, ApiPut } from "../../utils/Api/ApiData";
import { NavLink, Router, useHistory, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import Auth from "../../utils/auth";

export default function Payment(props) {
  const [paymentBox, setPaymentBox] = useState(false);
  const [loader, setloader] = useState(false);
  const Elements = useLocation();
  const [Getplan, setplan] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const Organization = Auth.getOrganization();
  const history = useHistory();

  // Know if user's subscription plan is preLaunch or standard
  const preLaunchOrStandard = () => {
    if (
      new Date(localStorage?.createdAt) <= new Date("June 1, 2023 00:00:00")
    ) {
      return "prelaunch";
    } else {
      return "standard";
    }
  };
  // Know if user's subscription plan is preLaunch or standard

  console.log("DDDDDDDDDDDDDDDDDAAAAA", props);
  // const localStorageData = JSON.parse(localStorage.getItem("orgtoken"));
  useEffect(() => {
    if (!props?.location?.state?.payment?._id) {
      toast.error("Email not provided, redirecting back.", {
        theme: "colored",
      });
      history.goBack();
    }
  }, [props, history]);
  const handlePayment = async (e) => {
    e.preventDefault();
    setloader(true);
    if (!stripe || !elements) {
      setloader(false);
      return;
    }
    const res = await ApiPost("payment/pay", {
      planId: props?.location?.state?.payment?._id,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("resresresresresresres", err);
        toast.error(err);
        setloader(false);
        return err;
      });

    // return;
    console.log("pricepricepriceprice", res);
    const clientSecret = res?.data?.payload?.client_secret;
    if (clientSecret) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: res?.data?.payload?.user?.email,
            address: {
              city: "null",
              country: "US",
              line1: "null demo",
              line2: "null",
              state: "null",
            },
            name: res?.data?.payload?.user?.email,
          },
        },
      });

      console.log("payment/paypayment/pay", result);
      if (result.error) {
        setloader(false);
        toast.error(result?.error?.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          const payData = {
            planId: props.location.state.payment._id,
            stripePaymentId: clientSecret,
          };

          const response = await ApiPost("myPlan/", payData)
            .then((data) => {
              setPaymentBox(!paymentBox);
              // if (Auth.getOrganization() && Auth.isUserAuthenticatedOrg()) {
              //   history.push({
              //     pathname: "/success",
              //     state: {
              //       payment: Elements?.state?.payment,
              //     },
              //   });
              // } else {
              //   history.push({
              //     pathname: "/success",
              //     state: {
              //       payment: Elements?.state?.payment,
              //     },
              //   });
              // }

              //   history.push({
              //     pathname: `/success`,
              //     state: {
              //       ...props?.location?.state,
              //       payment: Elements?.state?.payment,
              //       skip: Elements?.state?.skip,
              //       user: props?.location?.state?.user,
              //     },
              //   });

              history.push({
                pathname: "/accelerator",
                state: {
                  planId: data.data.payload.myplan.planId,
                  myplanId: data.data.payload.myplan._id,
                },
              });
              toast.success("Payment completed!");
              setloader(false);

              // history.push(`/logo-description`);
              // router;
            })
            .catch((err) => {
              setloader(false);
              console.log("err", err);
            });
        } else {
          setloader(false);
          toast.error("Payment failed");
        }
      }
    } else {
      setloader(false);
    }
  };

  return (
    <>
      <div className="payment-page">
        <div
          className="back-text-alignment-data"
          onClick={() => history.goBack()}
        >
          <svg
            viewBox="0 0 24 24"
            width="20"
            height="20"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="css-i6dzq1"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <p>Back to Plan</p>
        </div>
        <div className="payment-modal-box">
          <div className="payment-modal-header">
            <h2>Payment Details</h2>
          </div>
          <div className="payment-modal-body">
            <div className="plan-details">
              <h3>Selected Plan:</h3>
              <p>
                <strong>Name:</strong> {props?.location?.state?.payment?.title}
              </p>

              <p>
                <strong>Cost:</strong> ${props?.location?.state?.payment?.price}
              </p>
            </div>
            <div className="payment-fields">Enter Card Details</div>
            <div className="card-details">
              <CardInputs />
            </div>
            <div className="button-payment">
              <button
                disabled={loader}
                className="fill-buttons"
                onClick={handlePayment}
              >
                <Loader
                  type="Oval"
                  color="#FFF"
                  height={25}
                  width={25}
                  visible={loader}
                />
                {loader ? "" : "Pay"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
