// React imports start...
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  //   Routes,
  Switch,
  useHistory,
} from "react-router-dom";
import Contact from "../components/ContactForm/contact";
// React imports end...

// 3rd party packages start...
// 3rd party packages end...

// Dev imports start...
import RouteWrapper from "./RouteWrapper";

import Activities from "../Pages/Activities/Activities";
import WebinarDetails from "../Pages/WebinarDetails/WebinarDetails";
import LandingPage from "../Pages/LandingPage/LandingPage";
import ScrollTopOnRouteChange from "../utils/ScrollTopOnRouteChange";
import { navigation } from "../utils/frontendNavigation/frontendNavigation";
import ThankYou from "../components/ContactForm/thankyouPage";
import Applied from "../components/Applied/applied";
import AboutUs from "../components/About/about";
import ThanksForParticipant from "../Pages/ThanksForParticipant/ThanksForParticipant";
import Feature_Pricing from "../components/Feature_Pricing/Feature_Pricing";
import Login from "../components/Login/Login";
import Signup from "../components/Signup/Signup";
import OTPVerification from "../components/OTPVerification/OTPVerification";
import Dashboard from "../components/Dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Payment from "../components/Payment/Payment";
import Accelerator from "../components/Accelerator/Accelerator";
import Sidebar from "../components/Sidebar/Sidebar";
// Dev imports end...

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import Header from "../components/Layout/Header/Header";
// import Home from "../components/home/Home";
// import SignIn from "../components/Auth/SignIn/SignIn";
// import Footer from "../components/Layout/Footer/Footer";
// import About from "../components/About/About";
// import Terms from "../components/terms/terms";
// import Contact from "../components/Contact/Contact";
// import Privacy from "../components/privacy/privacy";
// import Cookie from "../components/cookie/cookie";

// import WhyLeaderbridge from "../components/WhyLeaderbridge/WhyLeaderbridge";
// import SignUp from "../components/Auth/SignUp/SignUp";
// import SwipePage from "../components/SwipePage/SwipePage";
// import Profile from "../components/Profile/Profile";
// import UserHeader from "../components/Layout/UserHeader/UserHeader";
// import ProfileSearch from "../components/ProfileSearch/ProfileSearch";
// import AskQuestion from "../components/AskQuestion/AskQuestion";
// import Connections from "../components/Connections/Connections";
// import MyQuery from "../components/MyQuery/MyQuery";
// import Notifications from "../components/Notifications/Notifications";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
// import Chat from "../components/Chat/Chat";
// import OTPVerification from "../components/Auth/OtpVarification/OTPVerification";
// import { ApiGet, ApiPost } from "../Helpers/Api/ApiData";
// import ProtectedRoute from "./ProtectedRoute";
// import SignedInRoute from "./SignedInRoute";
// import RouteWrapper from "./RouteWrapper";
// import Answer from "../components/Answer/Answer";
// import VerificationForm from "../components/Auth/VerificationForm/VerificationForm";
// import VerificationImage from "../components/Auth/UserVerificationImage/VerificationImage";
// import SignUpSubject from "../components/Auth/SignUpSubject/SignUpSubject";
// import Welcome from "../components/Auth/Welcome/Welcome";
// import SettingPage from "../components/SettingPage/SettingPage";
// import SeeAnswer from "../components/SeeAnswer/SeeAnswer";
// import ViewProfile from "../components/ViewProfile/ViewProfile";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import ProtectedDashboardRoute from "./ProtectedDashboardRoute";
import UnscheduledMeetingItem from "../components/UnscheduledMeetingItem/UnscheduledMeetingItem";
import Unscheduled from "../components/Unscheduled/Unscheduled";
// import { ToastContainer } from "react-toastify";
// import PasswordChange from "../components/Auth/PasswordChange/PasswordChange";
// import VerifyWorkEMail from "../components/VerifyWorkEmail/VerifyWorkEMail";
// import Query from "../components/MyQuery/Query";
// import Request from "../components/MyQuery/Request";
// import AnswerByMe from "../components/MyQuery/Answerby";
// import AnswerLater from "../components/MyQuery/AnswerLater";
// import LandingPage from "../components/landingPage/landingPage";
// import NewFooter from "../components/Layout/newFooter/newFooter";
// import NewHowItWork from "../components/NewHowItWork/NewHowItWork";
// import NewContact from "../components/newContact";
// import NewAbout from "../components/newAbout";
// import NewPricing from "../components/newPricing";
// import UpdateHeader from "../components/Layout/UpdateHeader/UpdateHeader";
// import UpdateFooter from "../components/Layout/newFooter/newFooter";
// import Active from "../components/Activation/Active";
// import Register from "../components/Auth/Register/Register";
// import OrganizationResetPassword from "../components/ResetPassword/OrganizationResetPassword";
// import LogoDescription from "../components/LogoDescription/LogoDescription";
// import OrganizationDashboard from "../components/OrganizationDashboard/OrganizationDashboard";
// import User from "../components/OrganizationDashboard/User/User";
// import Institution from "../components/Institution/Institution";
// import ProtectedRouteOrg from "./ProtectedRouteOrg";
// import RouteWrapperOrg from "./RouteWarperOrg";
// import MeetOurTeam from "../components/MeetourTeam/MeetOurTeam";
// import Company from "../components/CompanyUsers/Company";
// import Plan from "../components/Plan/Plan";
// import Payment from "../components/Payment/Payment";
// import Success from "../components/SuccessPayment/success";
// import Pricing from "../components/OrganizationDashboard/Pricing/Pricing";
// // import Invoice from "../components/SettingPage/componentToPrint";
// import OrganizationPlan from "../components/OrganizationPlan/organizationplan";
// import ShareQuestionWithSwipePage from "../components/ShareQuestionWithSwipePage/ShareQuestionWithSwipePage";
// import SetSubject from "../components/Auth/SignUpSubject/SetSubject";
// import ManualVerification from "../components/Auth/ManualVerification/ManualVerification";
// import Instructions from "../components/Auth/UserVerificationImage/Instructions";
// import Identity from "../components/Auth/UserVerificationImage/identity";
// import AskTakeSelfi from "../components/Auth/UserVerificationImage/AskTakeSelfi";
// import TakeCamera from "../components/Auth/UserVerificationImage/TakeCamera";
// import Document from "../components/Auth/UserVerificationImage/Document";
// import PreperDocument from "../components/Auth/UserVerificationImage/PreperDocument";
// import UserSwipe from "../components/userShowSwipe/UserSwipe";
// import NewProfile from "../components/NewProfile";
// import PaymentPricingPlan from "../components/OrganizationDashboard/PaymentPricing/Payment-prcing";
// // const stripePromise = loadStripe("pk_test_iKGeWV6n1oHwCLy1ZfcWfVmQ");
// // const stripePromise = loadStripe("pk_test_iKGeWV6n1oHwCLy1ZfcWfVmQ");

(() => {})();
// const UserSubLayout = ({ children }) => (
//   <>
//     <UserHeader />
//     {children}

//     <Footer />
//   </>
// );
const DefaultLayout = ({ children, match }) => (
  <>
    <Sidebar />
    {children}
    {/* <Footer /> */}
  </>
);
// const DefaultLayout1 = ({ children, match }) => (
//   <>
//     <UpdateHeader match={match} />
//     {children}
//     <UpdateFooter />
//   </>
// );

// const withoutHeader = ({ children, match }) => (
//   <>
//     {children}
//     <Footer />
//   </>
// );
// const withoutFooter = ({ children, match }) => <>{children}</>;
// const NewLayout = ({ children, match }) => (
//   <>
//     {children}
//     {/* <Footer /> */}
//     <NewFooter />
//   </>
// );
// const NewLayoutDashboard = ({ children, match }) => (
//   <>
//     {children}
//     {/* <Footer /> */}
//     {/* <NewFooter /> */}
//   </>
// );

const meta = {
  title: "Some Meta Title",
  description: "I am a description, and I can create multiple tags",
  canonical:
    "https://leaderbridgellc-my.sharepoint.com/:x:/g/personal/shashank_johnbehrgroup_com/ERdcqwe4XYBGkNy9fQA3c-8BcF-PoSHMh29yqgGIZPqoFw?e=F5iEZb",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "react,meta,document,html,tags",
    },
  },
};

function Layout() {
  const [CmdDesc, setCmdDesc] = useState();
  const [Allaboutpage, setAllaboutpage] = useState();
  const [allabouttrue, setallabouttrue] = useState();

  let storeAbout;
  let storeBlog;
  let storeContact;
  let storePrivacy;
  let storeCookie;
  let storeterms;

  const functiondata = CmdDesc?.map((data) => (
    <>
      {data.title == "about" && data.isActive == true && (storeAbout = true)}

      {data.title == "contact" &&
        data.isActive == true &&
        (storeContact = true)}

      {data.title == "privacy" &&
        data.isActive == true &&
        (storePrivacy = true)}

      {data.title == "cookie" && data.isActive == true && (storeCookie = true)}

      {data.title == "terms" && data.isActive == true && (storeterms = true)}

      {data.title == "blog" && data.isActive == true && (storeBlog = true)}
    </>
  ));

  // useEffect(() => {
  //     AllCms();
  // }, []);
  // const AllCms = () => {
  //     ApiGet("cms/get-cms")
  //     .then((res) => {
  //         setCmdDesc(res.data.payload.Cms);
  //     })
  //     .catch((err) => {
  //         console.log(err);
  //     });
  // };

  useEffect(() => {}, [Allaboutpage]);

  const [show, setShow] = useState({});

  console.log("HERE");

  return (
    <>
      <ScrollTopOnRouteChange />
      <Switch>
        <RouteWrapper
          exact={true}
          path="/"
          component={LandingPage}
          // layout={NewLayout}
        />

        <RouteWrapper
          exact={true}
          path="/activities"
          component={Activities}
          // layout={NewLayout}
        />

        <RouteWrapper
          exact={true}
          path="/Feature_Pricing"
          component={Feature_Pricing}
          // layout={NewLayout}
        />

        <RouteWrapper
          exact={true}
          path={`/${navigation?.webinarDetails}/:id`}
          component={WebinarDetails}
          // layout={NewLayout}
        />

        <RouteWrapper
          exact={true}
          path={`/thanks-for-participant`}
          component={ThanksForParticipant}
        />
        <RouteWrapper exact={true} path={"/contactForm"} component={Contact} />
        <RouteWrapper exact={true} path={"/login"} component={Login} />
        <RouteWrapper exact={true} path={"/signup"} component={Signup} />

        <RouteWrapper exact={true} path={"/thank-you"} component={ThankYou} />
        <RouteWrapper exact={true} path={"/services"} component={Applied} />
        <RouteWrapper exact={true} path={"/about"} component={AboutUs} />
        <RouteWrapper
          exact={true}
          path="/forgot-password"
          component={ForgotPassword}
          // layout={withoutHeader}
        />

        <RouteWrapper
          exact={true}
          path="/reset-password/:id"
          component={ResetPassword}
        />
        <ProtectedDashboardRoute
          exact={true}
          path={"/dashboard"}
          component={Dashboard}
        />

        <ProtectedDashboardRoute
          exact={true}
          path={"/Unscheduled"}
          component={Unscheduled}
        />

        <ProtectedRoute
          exact={true}
          path={"/accelerator"}
          component={Accelerator}
        />
        <ProtectedRoute exact={true} path={"/payment"} component={Payment} />
        <RouteWrapper
          exact={true}
          path={"/OTPVerification"}
          component={OTPVerification}
        />
      </Switch>
    </>
  );
}

export default Layout;
