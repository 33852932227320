// React imports start...
import { useRef, useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
// React imports end...

// 3rd party packages start...
import "../../fonts/Rockwell/ROCK.TTF";
// 3rd party packages end...

// Dev imports start...
import Hero from "../../components/Hero";
import Platforms from "../../components/Platforms/Platforms";
import Webinar from "../../components/Webinar/Activities";
// import WebinarInvitationLandingPage from "../../components/Webinar/WebinarInvitationLandingPage";
import WebinarList from "../../components/Webinar/WebinarList/WebinarList";

import { setAllWebinarsHandler } from "../../Pages/Activities/Activities";
import {
  leadership,
  leaderbridge,
  appliedPsychologists,
  leaderbridgeLink,
  appliedPsychologistsLink,
  spaceas,
  spaceasLink,
} from "../../utils/brandNames";
import { fetchAllWebinarsHandler } from "../../utils/Api/ApiCalls/webinar";
import { navigation } from "../../utils/frontendNavigation/frontendNavigation";
// Dev imports end...

import "./LandingPage.css";
import Scroller from "../../components/Scroller/Scroller";
import Journey from "../../components/Journey/Journey";

const LandingPage = () => {
  const history = useHistory();

  // Refs start
  const webinarFeaturesRef = useRef();
  // Refs end

  // Static data start...
  // State variables init state start...
  const allWebinarsInitState = {
    upcoming: [],
    archived: [],
  };
  // State variables init state start...
  // Static data end...

  // State variables...
  const [allWebinars, setAllWebinars] = useState(allWebinarsInitState);
  // State variables...

  // Effects start...
  useEffect(() => {
    document.title = "leadership.NET | Home";
  }, []);

  useEffect(() => {
    setAllWebinarsHandler(setAllWebinars);
  }, []);
  // Effects end...

  return (
    <>
      <div class="page-wrapper">
        <Hero />
        <Journey />

        <div class="page-content">
          {/* <section className="">
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                  <div class="">
                    <p class="">
                      <span class="fs-3 font-w-4 d-block">
                        {leadership} is a concentrated suite of software and
                        services that helps leaders solve problems.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <Webinar
                        webinarFeaturesRef={webinarFeaturesRef}
                    /> */}

          {/* <WebinarInvitationLandingPage /> */}
          <WebinarList
            webinars={allWebinars?.upcoming}
            title={`Upcoming Webinars`}
            navigateToWebinarDetails={true}
            customComponents={{
              seeMoreBtn: (
                <button
                  style={{
                    marginTop: "2rem",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: `${navigation.activities}`,
                    });
                  }}
                >
                  See More
                </button>
              ),
            }}
          />

          {/* <Webinar 
                        showFeatures={true}
                    /> */}

          {/* 
                    <Platforms
                        backgroundColor={`rgba(30, 29, 45, 0.9)`}
                        heading={`Our Network`}
                        textColor={`text-light`}
                    /> */}

          {/* <!--newsletter end--> */}
        </div>
      </div>
    </>
  );
};

export default LandingPage;
