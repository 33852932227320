import React, { useEffect, useState } from "react";
import "../styles/PricingTable.scss";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ApiGetNoAuth } from "../../../utils/Api/ApiData";
import Loader from "react-loader-spinner";

const pricingOptions = [
  {
    title: "Peer Practice",
    options: ["LeaderBridge Monthly - $16", "LeaderBridge Yearly - $96"],
    description: "Get access to peer support and practice tools.",
    price: "$16/mo or $96/yr",
    buttonLabel: "Join Now",
  },
  {
    title: "PhD Expert Support",
    options: [
      "Profile Calibration Meeting (includes 1 year of LeaderBridge access) - $384",
      "PhD Leadership Assessment (includes 1 year of LeaderBridge access) - $960",
    ],
    description: "Expert support with a focus on leadership development.",
    price: "$384 and $960",
    buttonLabel: "Get Started",
  },
  {
    title: "Customized Coaching and Assessment",
    options: [
      "3- and 6-Month Leadership Coaching - Prices Vary",
      "Deeper-Dive Assessment Battery - Prices Vary",
    ],
    description: "Tailored coaching and comprehensive assessments.",
    price: "Prices Vary",
    buttonLabel: "Book a Meeting",
  },
];

function PricingTable() {
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  const history = useHistory();

  const getPlan = () => {
    ApiGetNoAuth("plan/")
      .then((res) => {
        console.log("global.models.GLOBAL.PLAN", res);
        setPlan([...res?.data.payload.allPlan]);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((err) => {
        console.error("Error fetching plans:", err);
        setLoading(false); // Set loading to false on error as well
      });
  };

  useEffect(() => {
    getPlan();
  }, []);

  const handleRedirect = (url) => {
    window.location.href = url;
  };

  return (
    <div className="pricing-table">
      {loading ? ( // Conditional rendering based on loading state
        <div className="loader-container">
          <Loader
            type="Oval"
            color="#0f4ab0"
            height={40}
            width={40}
            visible={loading}
          />
        </div>
      ) : (
        <>
          <div className="pricing-cards">
            {plan.map((option, index) => (
              <div key={index} className={`pricing-card`}>
                <h2 className="card-title">{option.title}</h2>
                <p className="card-description">{option.description}</p>

                <ul className="card-options">
                  {option.options.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
                {option.price ? (
                  <h3 className="card-price">${option.price}</h3>
                ) : (
                  ""
                )}

                <button
                  className="card-button"
                  onClick={() => {
                    {
                      option.price
                        ? history.push({
                            pathname: `/payment`,
                            state: {
                              payment: option,
                            },
                          })
                        : history.push({
                            pathname: `/contactForm`,
                          });
                    }
                  }}
                >
                  {option.buttonLabel}
                </button>
              </div>
            ))}
          </div>
          <div className="footer-links">
            <NavLink to="/contactForm">Need Help? Contact Support.</NavLink>
          </div>
        </>
      )}
    </div>
  );
}

export default PricingTable;
