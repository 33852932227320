import React from "react";
import { Link } from "react-router-dom";
import "./ThanksForParticipant.css";

const ThanksForParticipant = (props) => {
  const { webinarDetails, email, name } = props.history.location.state;
  console.log("ThanksForParticipant", webinarDetails, email, props);
  return (
    <div className="background-image">
      <div className="thanks-container">
        <h2 className="thanks-heading">Thank You for Registering!</h2>
        <p className="thanks-text">
          We're excited to have you join our upcoming webinar:
        </p>

        <div className="webinar-info">
          <p>
            <strong>Title:</strong> {webinarDetails.title}
          </p>
          <p>
            <strong>Date:</strong> {webinarDetails.date}
          </p>
          <p>
            <strong>Time:</strong> {webinarDetails.time}
          </p>
          <p>
            <strong>Duration:</strong> Approximately {webinarDetails.duration}{" "}
            Minutes
          </p>
          <p>
            <strong>Platforms:</strong> {webinarDetails.platforms} (Webinar Link
            provided upon registration)
          </p>
        </div>

        <p className="confirmation-text">
          We've sent a confirmation email to {email}. Please check your inbox.
        </p>

        <Link to="/" className="back-to-home-link">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ThanksForParticipant;
