// React imports start...
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// React imports end...

// Dev imports start...
import NoItemsToShow from "../../common/NoItemToShow/NoItemToShow";
import { navigation } from "../../../utils/frontendNavigation/frontendNavigation";
import {
  leadership,
  leaderbridge,
  appliedPsychologists,
  mappingFromBrandNamesToLegalBrandNames,
} from "../../../utils/brandNames";
// Dev imports end...

// Css imports start...
import "./WebinarList.css";
// Css imports end...

const WebinarCard = ({
  title,
  date,
  time,
  app,
  _id,
  navigateToWebinarDetails,
}) => {
  const history = useHistory();

  // All handlers start...
  // Navigation handlers start...
  const navigateToActivities = (_id) => {
    history.push({
      pathname: `${navigation?.webinarDetails}/${_id}`,
    });
  };
  // Navigation handlers end...
  // All handlers end...

  return (
    <>
      <div
        onClick={() => {
          if (navigateToWebinarDetails) {
            navigateToActivities(_id);
          }
        }}
        style={{
          width: "100%",
          backgroundColor: "transparent",
          padding: "1rem 2rem",
          borderRadius: "1rem",
          cursor: navigateToWebinarDetails ? "pointer" : "default",
        }}
        className={`box-shadow border border-2 border-dark rounded-5`}
      >
        <h4
          style={{
            marginBottom: "1.25rem",
            fontSize: "1.25rem",
          }}
        >
          <span class="font-w-4">
            {mappingFromBrandNamesToLegalBrandNames(app)}{" "}
          </span>
          {/* <span className='d-block webinar-invitation-only-text'>(By Invitation Only)</span> */}
        </h4>

        <p
          style={{
            marginBottom: "0.25rem",
          }}
        >
          {title}
        </p>

        <p
          style={{
            marginBottom: "0.25rem",
          }}
        >
          {date}
        </p>

        <p
          style={{
            marginBottom: "0.25rem",
          }}
        >
          {time}
        </p>

        {/* <p
                    style={{
                        marginBottom: '0.25rem'
                    }}
                >Hosted : {app}</p> */}
      </div>
    </>
  );
};

const WebinarList = ({
  webinars,
  title,
  customComponents,
  navigateToWebinarDetails,
}) => {
  // State variables...
  const [paginatedWebinars, setPaginatedWebinars] = useState([]);
  // State variables...

  // All handlers start...
  const loadMoreWebinars = () => {
    setPaginatedWebinars(webinars);
  };
  // All handlers end...

  // Effects start...
  useEffect(() => {
    if (paginatedWebinars?.length === 0) {
      const webinarsTemp = webinars?.filter((webinar, idx) => {
        return idx >= 0 && idx <= 2;
      });

      setPaginatedWebinars(webinarsTemp);
    }
  }, [webinars]);
  // Effects end...

  return (
    <>
      <section
        style={{
          textAlign: "center",
          // margin: '2rem 0 0 0'
        }}
        className={`pt-0`}
      >
        <h3
          style={{
            marginBottom: "1.5rem",
          }}
        >
          {title}
        </h3>

        <div className={`webinar-list`}>
          {paginatedWebinars?.length > 0 ? (
            paginatedWebinars?.map((webinar, webinarIdx) => {
              return (
                <React.Fragment key={webinarIdx}>
                  <WebinarCard
                    title={webinar?.title}
                    date={webinar?.date}
                    time={webinar?.time}
                    app={webinar?.app}
                    _id={webinar?._id}
                    navigateToWebinarDetails={navigateToWebinarDetails}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <NoItemsToShow />
          )}
        </div>

        {!customComponents?.seeMoreBtn &&
        paginatedWebinars?.length < webinars?.length ? (
          <button
            onClick={loadMoreWebinars}
            style={{
              marginTop: "2rem",
            }}
          >
            Load More
          </button>
        ) : null}

        {customComponents?.seeMoreBtn}
      </section>
    </>
  );
};

export default WebinarList;
