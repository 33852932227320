import React from "react";
import "../styles/FeatureTable.scss";

const features = [
  {
    name: "LeaderBridge Peer Practice Portal",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  {
    name: "leadership.NET Context Profile",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  {
    name: "Mandatory Verification",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  {
    name: "Practice Set Up and Matching",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  { name: "Default Anonymity", plans: ["Y", "Y", "Y", "Y", "Y", "Y"] },
  {
    name: "Controlled Chat, Audio, and Video",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  {
    name: "Peer Performance Rating",
    plans: ["Y", "Y", "Y", "Y", "Y", "Y"],
  },
  { name: "Inner Circle", plans: ["Y", "Y", "Y", "Y", "Y", "Y"] },
  {
    name: "Profile Calibration Meeting",
    plans: ["", "", "Y", "", "", ""],
  },
  { name: "PhD Leadership Assessment", plans: ["", "", "", "Y", "", ""] },
  {
    name: "3- and 6-Month Leadership Coaching",
    plans: ["", "", "", "", "Y", ""],
  },
  {
    name: "Deeper-Dive Assessment Battery",
    plans: ["", "", "", "", "", "Y"],
  },
  // Add a new feature for pricing
  {
    name: "Pricing",
    plans: ["$16/Monthly", "$96/Yearly", "$384", "$960", "Varies", "Varies"],
  },
];

const headers = [
  "Features",
  "LeaderBridge Monthly",
  "LeaderBridge Yearly",
  "Profile Calibration Meeting",
  "PhD Leadership Assessment",
  "3- and 6-Month Leadership Coaching",
  "Deeper-Dive Assessment Battery",
];

function FeatureTable() {
  return (
    <div className="feature-table">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, index) => (
              <tr
                key={index}
                className={
                  feature.name === "Pricing" ? "highlight" : "high-light"
                }
              >
                <td>{feature.name}</td>
                {feature.plans.map((plan, idx) => (
                  <td key={idx}>
                    {plan === "Y" ? (
                      <i
                        className="icon fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      plan
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FeatureTable;
