import React, { useEffect, useState } from 'react';

// 3rd party packages start...
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// 3rd party packages end...

// Dev imports start...
import GreyBackdrop from '../../Backdrops/GreyBackdrop';
import GeneralFormComponent from '../../Forms/GeneralFormComponent/GeneralFormComponent';
import { leadershipLink } from "../../../utils/brandNames";
import { convertValueToTypeHandler } from '../../../utils/convertValueToAppropriateType';
import { buildSuccessToastCard } from "../../../utils/showUserFeedbackMessages/reactToastCardBuilder";
import { ApiPutNoAuth } from '../../../utils/Api/ApiData';
// Dev imports end...


import "./InvitationForm.css";

// Child components start...
const InvitationFormLinkContainer = ({
    _id
}) => {

    // Static data start...
    // State variables init state start...
    const linkCopiedInit = false;
    // State variables init state start...


    // Other static variables start...
    const leadershipWebinarDetailsLink = `${leadershipLink}/webinar-details/${_id}`;
    // Other static variables end...
    // Static data end...

    // State variables start...
    const [linkCopied, setLinkCopied] = useState(linkCopiedInit);
    // State variables end...

    // Effects start...
    useEffect(() => {
        const asyncHandler = async () => {
            if (linkCopied) {
                const payload = {
                    name: `webinar-invitation-link-copied-count`
                }

                await ApiPutNoAuth(`analytics/webinar-invitation-link-copied`, payload);
            }
        }

        asyncHandler();
    }, [linkCopied]);
    // Effects end...

    return (
        <>
            <div className='d-flex flex-column align-items-start'>
                <span>Invite a friend: </span>
                <div className='d-flex gap-1 align-items-center'>
                    <span className={`text-start`}>{leadershipWebinarDetailsLink}</span>   
                    <Button 
                        variant="outlined"
                        onClick={(e) => {
                            navigator.clipboard.writeText(leadershipWebinarDetailsLink);
                            buildSuccessToastCard(`Link copied to the clipboard!`);
                            if (!linkCopied) {
                                setLinkCopied(true);
                            }
                        }}
                    >Copy Link</Button>
                </div>
            </div>
        </>
    )
}
// Child components end...

const InvitationForm = ({
    setShowFormState,
    formState,
    setFormState,
    webinarDetails,
    _id
}) => {

    // Static data start...
    // State variables init state start...
    const formFields = [{
        fieldName: `Name`,
        payloadFieldName: 'name',
        value: ``,
        type: `String`,
        required: true,
        errorMessage: `Name cannot contain numbers or special characters`,
        showErrorMessage: false,
        isValidated: false,
        // If 'checkValidationHandler' returns true, then there's no error, else it contains error...
        checkValidationHandler: (value) => {
            return value?.match(/^[a-zA-Z ]+$/);
            // return (typeof value === "string" && value !== "");
            // return value?.match(/^[a-zA-Z]+$/);
        }
    }, {    
        fieldName: `Email`,
        payloadFieldName: 'email',
        value: ``,
        type: `String`,
        required: true,
        errorMessage: `Please enter a valid email`,
        showErrorMessage: false,
        isValidated: false,
        // If 'checkValidationHandler' returns true, then there's no error, else it contains error...
        checkValidationHandler: (value) => {
            return value?.match(/[a-z0-9]+@[a-z]+\.[a-z]+/);
        }
    }
    // {
    //     fieldName: `LinkedIn Profile`,
    //     payloadFieldName: 'linkedInProfile',
    //     value: ``,
    //     type: `String`,
    //     required: true,
    //     errorMessage: `Please enter a valid LinkedIn profile URL`,
    //     showErrorMessage: false,
    //     isValidated: false,
    //     // If 'checkValidationHandler' returns true, then there's no error, else it contains error...
    //     checkValidationHandler: (value) => {
    //         // return value?.match(/http(s)?:\/\/(www.)?linkedin.com\/[.]*/);
    //         return value?.match(/[.]*linkedin.com\/[.]*/);
    //     }
    // }
    ];
    // State variables init state start...
    // Static data end...

    // State variables...
    // State variables...

    // All handlers...
    // State update handlers start...
    const updateFormStateHandler = (fieldName, value) => {
        setFormState(formState => {
            return formState?.map(currentField => {
                if (currentField?.fieldName === fieldName) {
                    return {
                        ...currentField,
                        value: convertValueToTypeHandler(value, currentField?.type),
                        isValidated: currentField?.checkValidationHandler(value),
                        showErrorMessage: !currentField?.checkValidationHandler(value)
                    }
                } return currentField;
            })
        })
    }

    // Clear formState start...
    const clearFormStateHandler = () => {
        setShowFormState(false);
        setFormState(formFields);
    }
    // Clear formState end...
    // State update handlers end...

    // Validate entire formState handler start...
    const checkFormValidationHandler = (formState) => {
        for (let formFieldIdx = 0; formFieldIdx < formState?.length; formFieldIdx++) {
            if (!formState[formFieldIdx]?.isValidated) {
                return false;
            }
        }

        return true;
    }
    // Validate entire formState handler start...

    // Map FormState data to backend request payload start...
    const mapFormStateToBackendRequestPayloadHandler = () => {
        // Validate the entire form first start...
        if (!checkFormValidationHandler(formState)) {
            return null;
        }
        // Validate the entire form first end...

        // Build the payload start...
        const payload = {
            webinarId: webinarDetails?._id
        };

        for (let formFieldIdx = 0; formFieldIdx < formState?.length; formFieldIdx++) {
            payload[formState[formFieldIdx]?.payloadFieldName] = formState[formFieldIdx]?.value;
        } 

        return payload;
        // Build the payload end...
    }
    // Map FormState data to backend request payload end...
    // All handlers...


    // Effects start...
    useEffect(() => {
        if (!formState) {
            setFormState(formFields);
        }
    }, []);
    // Effects end...

    return (
        <>
            {/* <GreyBackdrop
                setShowFormState={setShowFormState}
            > */}
                <GeneralFormComponent
                    backdropFormHeading={`Webinar Invitation Form`}
                    setShowFormState={setShowFormState}
                    formFields={formFields}
                    formState={formState}
                    setFormState={setFormState}
                    updateFormStateHandler={updateFormStateHandler}
                    checkFormValidationHandler={checkFormValidationHandler}
                    mapFormStateToBackendRequestPayloadHandler={mapFormStateToBackendRequestPayloadHandler}
                    clearFormStateHandler={clearFormStateHandler}
                    InvitationFormLinkContainer={InvitationFormLinkContainer}
                    webinarDetails={webinarDetails}
                    _id={_id}
                    styles={{
                        width: `70%`,
                        margin: `0 auto`
                    }}

                />
            {/* </GreyBackdrop> */}
        </>
    )
}

export default InvitationForm;