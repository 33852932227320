import React from "react";
import "./thankYou.css";
import backgroundImage from "../../images/bground.png";

const ThankYou = () => {
  return (
    <div className="thank-you-container">
      <div className="thank-you-content">
        <div className="thank-you-header">
          <h1>Thank You!</h1>
          <p>Your message has been successfully received.</p>
        </div>
        <div className="thank-you-message">
          <p>
            We appreciate you taking the time to reach out to us. Our team will
            carefully review your message and respond promptly.
          </p>
        </div>
        <div className="contact-info">
          <p>
            In the meantime, feel free to explore more of our services on our
            website or follow us on social media for updates.
          </p>
          <p>
            If you have any urgent inquiries, you can contact us directly at
            support@leadership.NET.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
