import React from "react";
import PricingTable from "../Table/Pricing/PricingTable";
import FeatureTable from "../Table/Pricing/FeatureTable";
import CallToAction from "../Table/Pricing/CallToAction";
import "./Feature_Pricing.scss";
import Old_Pricing from "../Table/Pricing/Old_Pricing";

function Feature_Pricing() {
  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Plans and Pricing</h1>
        <p>For leaders, both new and experienced</p>
      </header>
      <main>
        {/* <PricingTable /> */}
        <Old_Pricing />
        <FeatureTable />
        {/* <CallToAction /> */}
      </main>
    </div>
  );
}

export default Feature_Pricing;
