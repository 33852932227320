const convertValueToTypeHandler = (value, type) => {
    let sanitizedValue;
    if (type === `String`) {
        sanitizedValue = String(value);
    } else if (type === `Number`) {
        sanitizedValue = Number(value);
    } else if (type === `Boolean`) {
        sanitizedValue = Boolean(value);
    }

    return sanitizedValue;
}

export { convertValueToTypeHandler };