import React from "react";

import Leadership from "../logos/Leadership.NET";

import {
  leadership,
  leaderbridge,
  appliedPsychologists,
} from "../../utils/brandNames";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div
        style={{
          marginTop: "100px",
        }}
      ></div>
      {/* <footer class="bg-dark py-7">
        <div class="container">
          <div class="d-flex footer-item justfy-content-between">
            <div class="footer-item1 mb-5 mb-lg-0 text-center">
              <Leadership backgroundColor={"dark"} />
            </div>
            <div class="text-center">
              <div class="d-flex gap-5"></div>
            </div>
          </div>

          <div class="row my-5">
            <div class="col">
              <hr class="m-0"></hr>
            </div>
          </div>

          <div class="d-flex footer-item justify-content-between align-items-center mb-5">
            <div class="text-light">Copyright ©2023 All rights reserved</div>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default Footer;
