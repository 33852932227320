// React imports start...
import { useEffect } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useHistory,
  Routes,
} from "react-router-dom";
import About from "./components/ContactForm/contact";
// React imports end...

// 3rd party packages start...
import { ToastContainer, toast } from "react-toastify";
import ReactGA from "react-ga4";
// 3rd party packages end...

// Dev imports start...
import Header from "./components/Header";
import Body from "./components/Body";
import PerformanceCarousel from "./components/PerformanceCarousel";
import Footer from "./components/Footer/Footer";
import Layout from "../src/Route/index";
import ScrollTopOnRouteChange from "./utils/ScrollTopOnRouteChange";
// Dev imports end...

// Css imports start...
import "./themeht.com/template/winck/ltr/assets/css/animate.css";
import "./themeht.com/template/winck/ltr/assets/css/bootstrap.min.css";
import "./themeht.com/template/winck/ltr/assets/css/line-awesome.min.css";
import "./themeht.com/template/winck/ltr/assets/css/magnific-popup.css";
import "./themeht.com/template/winck/ltr/assets/css/owl.carousel.css";
import "./themeht.com/template/winck/ltr/assets/css/spacing.css";
import "./themeht.com/template/winck/ltr/assets/css/theme.min.css";
import "./themeht.com/template/winck/ltr/assets/css/custom/index.css";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./utils/styles/common.css";
// Css imports end...

function App() {
  // Effects start...
  useEffect(() => {
    ReactGA.initialize("G-73GJXPYDD4");
    ReactGA.send({
      hitType: "pageview",
      page: document.location.pathname,
      title: "Custom Title",
    });
    // ReactGA.event({
    //   category: 'test',
    //   action: 'test event'
    // });
  }, []);
  // Effects end...

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <Route
        path="/"
        render={() => {
          return <Layout />;
        }}
      />

      <Footer />
    </div>
  );
}

export default App;
