import React from "react";
import "./DefaultLayout.scss";
import Sidebar from "../components/Sidebar/Sidebar";

function DefaultLayout({ children }) {
  return (
    <div className="default-layout">
      <Sidebar />
      <div className="main-content">{children}</div>
    </div>
  );
}

export default DefaultLayout;
