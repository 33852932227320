import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/auth";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKeyEnvironmentHandler } from "../utils/environmentConfigurationHandlers";
import { Elements } from "@stripe/react-stripe-js";
import DefaultLayout from "./DefaultLayout";

const ProtectedDashboardRoute = ({ component: Component, ...rest }) => {
  let token = Auth.getToken();

  const stripePromise = loadStripe(
    stripeKeyEnvironmentHandler({
      liveKey: process.env.REACT_APP_PK_LIVE,
      testKey: process.env.REACT_APP_PK_TEST,
    })
  );

  console.log(
    "process.env.REACT_APP_PK_LIVEprocess.env.REACT_APP_PK_LIVE",
    process.env.REACT_APP_PK_LIVE
  );

  return (
    <Elements stripe={stripePromise}>
      <Route
        {...rest}
        render={(props) =>
          token ? (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </Elements>
  );
};

export default ProtectedDashboardRoute;
