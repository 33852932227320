// React imports start...
import React from "react";
// React imports end...

const NoItemsToShow = () => {
    return (
        <React.Fragment>
            <div>
                <p
                    className={`fs-5`}
                >No items to show</p>
            </div>
        </React.Fragment>
    )
}

export default NoItemsToShow;