import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Signup.scss";
import { ApiPostNoAuth } from "../../utils/Api/ApiData";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Signup = () => {
  const [loading, setLoading] = useState(false); // Add loading state
  let history = useHistory();

  const saveUser = async (data) => {
    setLoading(true); // Set loading to true when starting the API call
    await ApiPostNoAuth("user/registration", data)
      .then((res) => {
        console.log("dffffffffffff", res);
        toast.success(res.data.message, { theme: "colored" });
        history.push({
          pathname: "/OTPVerification",
          state: { email: data.email },
        });
      })
      .catch((err) => {
        console.log("errrr", err);
        toast.error(err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the API call finishes
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      saveUser(values);
    },
  });

  return (
    <div className="login-container">
      <div className="welcome-section">
        <img src="/assets/images/login-left-banner.jpg" alt="Welcome Banner" />
      </div>
      <div className="login-section">
        <h2>Sign Up</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="input-group">
            <i className="fa-solid fa-envelope icon"></i>{" "}
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={
                formik.touched.email && formik.errors.email ? "input-error" : ""
              }
            />
          </div>
          <div className="input-group">
            <i className="fas fa-lock icon"></i>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={
                formik.touched.password && formik.errors.password
                  ? "input-error"
                  : ""
              }
            />
          </div>
          <div className="options">
            {/* <label>
              <input
                type="checkbox"
                id="remember"
                name="remember"
                onChange={formik.handleChange}
                checked={formik.values.remember}
              />
              Remember me
            </label> */}
          </div>
          <button type="submit" disabled={loading}>
            {loading ? <i className="fas fa-spinner fa-spin"></i> : "Sign Up"}
          </button>
          <p>
            Already have an account? <Link to="/login">Sign In here</Link>.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Signup;
