import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { ApiGet } from "../../utils/Api/ApiData";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import UnscheduledMeetingItem from "../UnscheduledMeetingItem/UnscheduledMeetingItem";
import MeetingItem from "../MeetingItem/MeetingItem";

const Dashboard = () => {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const getMyPlan = async () => {
    try {
      const res = await ApiGet("myPlan/");
      setMeetings(res?.data?.payload?.getMyPlan || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching meetings:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyPlan();
  }, []);

  const handleScheduleMore = () => {
    history.push("/Feature_Pricing");
  };

  return (
    <div className="unscheduled-meetings">
      <div className="meeting-list">
        {loading ? (
          <div className="loader-container">
            <Loader
              type="Oval"
              color="#0f4ab0"
              height={40}
              width={40}
              visible={loading}
            />
          </div>
        ) : (
          <>
            <div className="meeting-header">
              <h2>Scheduled Meetings</h2>
              <button className="schedule-button" onClick={handleScheduleMore}>
                Schedule More Meetings
              </button>
            </div>
            <div className="meetings">
              {meetings.length > 0 ? (
                meetings.map((meeting, index) => (
                  <MeetingItem key={index} meeting={meeting} />
                ))
              ) : (
                <p>No upcoming meetings</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
