import React from "react";
import "./Scroller.scss";

function Scroller() {
  const sections = [
    {
      heading: "<u>D</u>efine",
      image: "/assets/images/Define.png",
      beside: "/assets/images/define1_cleanup.jpg",
      alt: "Peer-to-peer practice",
    },
    {
      heading: "<u>O</u>wn",
      image: "/assets/images/Own.png",
      beside: "/assets/images/Own_Image.jpeg",
      alt: "Expert Coaching",
    },
    {
      heading: "<u>E</u>nlist",
      image: "/assets/images/Enlist.png",
      beside: "/assets/images/Enlist_image.jpeg",
      alt: "Expert Coaching",
    },
    {
      heading: "<u>R</u>esolve",
      image: "/assets/images/Resolve.png",
      image1: "/assets/images/Resolve1.png",
      beside: "/assets/images/resolve_image_image.jpg",
      alt: "Expert Coaching",
    },
    {
      heading: "<u>R</u>eward",
      image: "/assets/images/rating.png",
      beside: "/assets/images/Rewards_Image.png",
      alt: "Expert Coaching",
    },
    {
      heading:
        "Verification for <u>B</u>est Matching & Anonymity for Boosted Focus",
      image: "/assets/images/Instruction.png",
      alt: "Verification and Anonymity",
    },
    {
      heading: "Engage with peers on your terms via chat, audio, or video",
      image: "/assets/images/chatmessage.png",
      alt: "Engage with peers on your terms via chat, audio, or video",
    },
    {
      heading: "Absolutely No AI! Just Real Human Guidance",
      image: "/assets/images/NoAI.jpeg",
      alt: "Human Guidance",
    },
    {
      heading: "iOS & Android Coming Soon",
      image: "/assets/images/MicrosoftTeams-image.png",
      type: true,
      alt: "Mobile App Coming Soon",
    },
  ];

  return (
    <section className="scroller-section">
      {sections.map((section, index) => (
        <div className="scroller-heading-section" key={index}>
          {index % 2 === 0 ? (
            <>
              <div
                className="scroller-content"
                style={
                  section.image && section.image1 ? { marginLeft: "150px" } : {}
                }
              >
                <h2 dangerouslySetInnerHTML={{ __html: section.heading }}></h2>
                {section?.beside && <img src={section?.beside} />}

                <p>{section.description}</p>
              </div>
              <div
                className={
                  section.image && section.image1
                    ? "scroller-image-two"
                    : section.type
                    ? "scroller-image-phone"
                    : "scroller-image"
                }
              >
                <img src={section.image} alt={section.alt} />
                {section.image1 && (
                  <img src={section.image1} alt={section.alt} />
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className={
                  section.image && section.image1
                    ? "scroller-image-two"
                    : section.type
                    ? "scroller-image-phone"
                    : "scroller-image"
                }
              >
                <img src={section.image} alt={section.alt} />
                {section.image1 && (
                  <img src={section.image1} alt={section.alt} />
                )}
              </div>
              <div
                className={
                  section.image && section.image1
                    ? "scroller-content left-margin"
                    : "scroller-content"
                }
              >
                <h2 dangerouslySetInnerHTML={{ __html: section.heading }}></h2>
                {section?.beside && <img src={section?.beside} />}
                <p>{section.description}</p>
              </div>
            </>
          )}
        </div>
      ))}
    </section>
  );
}

export default Scroller;
