import React, { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useHistory } from "react-router-dom"; // Import useHistory for navigation
import "./Accelerator.scss";
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPostNoAuth,
  CalendlyGetNoAuth,
} from "../../utils/Api/ApiData";
import Loader from "react-loader-spinner";

const Accelerator = (props) => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const history = useHistory(); // Initialize useHistory
  console.log("propsprops", props);

  useCalendlyEventListener({
    onProfilePageViewed: (e) =>
      console.log(e.data.payload, "onProfilePageViewed"),
    onDateAndTimeSelected: (e) =>
      console.log(e.data.payload, "onDateAndTimeSelected"),
    onEventTypeViewed: (e) => console.log(e.data.payload, "onEventTypeViewed"),
    onEventScheduled: async (e) => {
      setLoader(true);

      console.log(
        "wwwwwwwwwwwwww",
        e?.data?.payload?.invitee?.uri,
        props.location.state.planId,
        props.location.state.myplanId
      );
      await CalendlyGetNoAuth(e?.data?.payload?.invitee?.uri)
        .then(async (res) => {
          console.log("wwwwwwwww22222wwwwwwwwwwwwwwwwwww", res);
          await CalendlyGetNoAuth(res?.data.resource.event).then(
            async (event) => {
              console.log("eventevent", event);
              let body = {
                accelerator: {
                  ...res?.data.resource,
                  uri: res?.data.resource?.uri,
                  planId: props.location.state.planId,
                  myplanId: props.location.state.myplanId,
                  resource: event?.data.resource,
                },
              };

              await ApiPost("meeting/", body)
                .then((res) => {
                  // getAccelerator();

                  history.push({
                    pathname: "/dashboard",
                  });
                })
                .catch((err) => {
                  setLoader(false);
                });
            }
          );
        })
        .catch((err) => {
          setLoader(false);
        });
    },
  });

  const getAccelerator = async () => {
    // setLoader(true);
    await ApiGet(`myPlan/check-plan/id=${props?.location?.state?.myplanId}`)
      .then((res) => {
        if (res?.data.payload.existingMeeting) {
          console.log("myPlan/check-plan", res);
          setLoader(false);
          return history.goBack();
        }
        // setUser(res?.data?.payload?.accelerator);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getAccelerator();
  }, [props?.location?.state?.myplanId]);

  // useEffect(() => {
  //   getAccelerator();
  // }, []);

  const handleSignIn = () => {
    history.push("/signin"); // Navigate to the sign-in page
  };

  const replaceDomain = (url) => {
    const segments = url.split("/");
    const dynamicPath = segments.slice(-2).join("/"); // Get the last two segments and join them with '/'
    const baseUrl = "https://calendly.com/john-w8vp/accelerator-meeting/";
    const fullUrl = baseUrl + dynamicPath;

    return fullUrl;
  };
  if (loader) {
    return (
      <div className="fullscreen-loader">
        <Loader
          type="Oval"
          color="#0f4ab0"
          height={40}
          width={40}
          visible={loader}
        />
      </div>
    );
  } else {
    if (!props?.location?.state?.planId || !props?.location?.state?.myplanId) {
      return history.goBack();
    } else {
      return (
        <div className="accelerator-container">
          {console.log("uriuriuriuri", user)}
          <div className="calendly-widget">
            <InlineWidget
              url={
                user?.uri
                  ? replaceDomain(user?.uri)
                  : "https://calendly.com/john-w8vp/accelerator-meeting"
              }
              prefill={{
                email: props?.location?.state?.email,
                name: props?.location?.state?.name,
                smsReminderNumber: props?.location?.state?.phone,
                guests: ["john@johnbehrgroup.com", "john@johnbehrgroup.com"],
                customAnswers: {
                  a1: "a1",
                  a2: "a2",
                  a3: "a3",
                  a4: "a4",
                  a5: "a5",
                  a6: "a6",
                  a7: "a7",
                  a8: "a8",
                  a9: "a9",
                  a10: "a10",
                },
                date: new Date(Date.now() + 86400000),
              }}
              utm={{
                utmCampaign: "Spring Sale 2023",
                utmContent: "Link Click",
                utmMedium: "Social Media",
                utmSource: "Facebook",
                utmTerm: "Spring",
              }}
            />
          </div>
          {user?.uri && (
            <button className="back-to-signin-button" onClick={handleSignIn}>
              Back to Sign In
            </button>
          )}
        </div>
      );
    }
  }
};

export default Accelerator;
