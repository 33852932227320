import React from "react";
import "../styles/CallToAction.scss";

function CallToAction() {
  return (
    <div className="call-to-action">
      <a href="#initiate-peer-practice" className="cta-button">
        Initiate Peer Practice
      </a>
      <a href="#initiate-phd-support" className="cta-button">
        Initiate PhD Expert Support
      </a>
      <a href="#book-coaching" className="cta-button">
        Book a Meeting
      </a>
    </div>
  );
}

export default CallToAction;
