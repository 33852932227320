export const leadership = "leadership.NET ™";
export const leadershipLink = "https://leadership.net";

export const leaderbridge = "LeaderBridge®";
export const leaderbridgeLink = "https://leaderbridge.com";

export const appliedPsychologists = "Applied Psychologists LLC";
export const appliedPsychologistsLink = "https://appliedpsychologists.com";

export const spaceas = "Spaceas™";
export const spaceasLink = "https://spaceas.com";

export const DOERRCopyright = () => {
  return (
    <span className={`doer-r`}>
      DOER-R<span className={`copyright`}>&copy;</span>
    </span>
  );
};

export const mappingFromBrandNamesToLegalBrandNames = (brandName) => {
  if (brandName === "LeaderBridge") {
    return leadership;
  } else if (brandName === "Applied Psychologists") {
    return appliedPsychologists;
  } else if (brandName === "Spaceas") {
    return "Spaceas";
  } else {
    return leadership;
  }
};
