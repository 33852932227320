// React imports start...
import React from "react";
import { Route } from "react-router-dom";
// React imports end...

// 3rd party packages start...
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe("pk_test_iKGeWV6n1oHwCLy1ZfcWfVmQ");
// // const stripePromise = loadStripe("pk_live_FxxywqPiSUO4H1EgeyVZjPKc");
// 3rd party packages end...



function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
    return (
        <Route
        {...rest}
        render={(props) => (
            // <Elements stripe={stripePromise}>
            // <Layout {...props}>
            <Component {...props} />
            // </Layout>
            // </Elements>
        )}
        />
    );
}

export default RouteWrapper;
