import { toast } from "react-toastify";


const buildSuccessToastCard = (message) => {
    toast.success(`${message || `Success!!`}`, { theme: "colored" });
}


const buildFailureToastCard = (message) => {
    toast.error(`${message || `Some error occured!!`}`, { theme: "colored" });
}


export { buildSuccessToastCard, buildFailureToastCard };