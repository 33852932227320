import React from "react";
import "./about.css";

const AboutUs = () => {
  return (
    <div className="about-us">
      <header className="header-about">
        <h1>About Us</h1>
      </header>
      <main className="main-about">
        <section className="mission">
          <div className="mission-content">
            <h2>Our Mission</h2>
            <p>
              Leaders helping leaders solve leadership problems. Focuses on
              leadership challenges. Designed with leaders in mind.
            </p>
          </div>
        </section>
        <section className="founder">
          <div className="founder-content">
            <p>
              leadership.NET has worked with thousands of executives at over 100
              companies in the United States, Europe, India, Japan, and
              Southeast Asia.
            </p>
            <p>
              In 2020, leadership.NET launched LeaderBridge, a SaaS platform
              designed to connect leaders with their peers for knowledge sharing
              and mutual support.
            </p>
            <p>
              LeaderBridge evolved from the firm’s practice of matching clients
              with peers of similar backgrounds to enhance their professional
              development.
            </p>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2021 leadership.NET. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default AboutUs;
