import React, { useEffect, useState } from "react";
import "./Unscheduled.scss";
import { ApiGet } from "../../utils/Api/ApiData";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import UnscheduledMeetingItem from "../UnscheduledMeetingItem/UnscheduledMeetingItem";

const Unscheduled = () => {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const getMyPlan = async () => {
    try {
      const res = await ApiGet("myPlan/get-unscheduled-plan");
      setMeetings(res?.data?.payload?.getMyPlan || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching meetings:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyPlan();
  }, []);

  const handleScheduleMore = () => {
    history.push("/Feature_Pricing");
  };

  return (
    <div className="unscheduled-meetings">
      <div className="meeting-list">
        {loading ? (
          <div className="loader-container">
            <Loader
              type="Oval"
              color="#0f4ab0"
              height={40}
              width={40}
              visible={loading}
            />
          </div>
        ) : (
          <>
            <div className="meeting-header">
              <h2>Unscheduled Meetings</h2>
              <button className="schedule-button" onClick={handleScheduleMore}>
                Schedule More Meetings
              </button>
            </div>
            {meetings.length > 0 ? (
              <div className="meetings">
                {meetings.map((meeting, index) => (
                  <UnscheduledMeetingItem key={index} meeting={meeting} />
                ))}
              </div>
            ) : (
              <div className="no-meetings">
                <p>No unscheduled meetings</p>
                {/* <button
                  className="schedule-button"
                  onClick={handleScheduleMore}
                >
                  Schedule Your First Meeting
                </button> */}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Unscheduled;
