import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Login.scss";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ApiPostNoAuth } from "../../utils/Api/ApiData";
import Auth from "../../utils/auth";

const Login = () => {
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const login = async (data) => {
    setLoading(true);
    await ApiPostNoAuth("user/login", data)
      .then((res) => {
        toast.success(res.data.message, { theme: "colored" });

        if (res.data.otp) {
          history.push({
            pathname: "/OTPVerification",
            state: { email: data.email },
          });
        } else {
          Auth.setAuthToken(res?.data?.payload.token);
          history.push({
            pathname: "/dashboard",
          });
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log("errrr", err);
        toast.error(err);
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      login(values);
    },
  });

  const handleLeaderBridgeLogin = () => {
    window.location.href = "https://www.leaderbridge.com/signin"; // Redirect to LeaderBridge login page
  };

  return (
    <div className="login-container">
      <div className="welcome-section">
        <img src="/assets/images/login-left-banner.jpg" alt="Welcome Banner" />
      </div>
      <div className="login-section">
        <h2>Login</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="input-group">
            <i className="fa-solid fa-envelope icon"></i>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={
                formik.touched.email && formik.errors.email ? "input-error" : ""
              }
            />
          </div>
          <div className="input-group">
            <i className="fas fa-lock icon"></i>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={
                formik.touched.password && formik.errors.password
                  ? "input-error"
                  : ""
              }
            />
          </div>
          <div className="options">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <button type="submit" disabled={loading}>
            {loading ? <i className="fas fa-spinner fa-spin"></i> : "Login"}
          </button>

          <p>
            Don't have an account? <Link to="/signup">Sign Up here</Link>.
          </p>

          <button
            type="button"
            className="leaderbridge-login-button"
            onClick={handleLeaderBridgeLogin}
          >
            Login with LeaderBridge
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
