// React imports start...
import React, { useState, useEffect } from 'react';
// React imports end...

// Dev imports start...
// import InvitationForm from './InvitationForm/InvitationForm';
// import UpcomingWebinars from './UpcomingWebinars/UpcomingWebinars';
// import ArchivedWebinars from './ArchivedWebinars/ArchivedWebinars';
import WebinarList from '../../components/Webinar/WebinarList/WebinarList';
import { leadership, leaderbridge, appliedPsychologists } from "../../utils/brandNames";
import { fetchAllWebinarsHandler } from "../../utils/Api/ApiCalls/webinar";
import { buildSuccessToastCard, buildFailureToastCard } from "../../utils/showUserFeedbackMessages/reactToastCardBuilder";
// Dev imports end...


export const setAllWebinarsHandler = async (setState) => {
    const fetchedAllWebinars = await fetchAllWebinarsHandler();

    if (fetchedAllWebinars) {
        const allWebinarsTemp = fetchedAllWebinars?.data?.payload?.fetchedAllWebinars;
        const upcoming = [];
        const archived = [];

        const currentDate = new Date();

        for (let i = 0; i < allWebinarsTemp?.length; i++) {
            const webinarDate = new Date(allWebinarsTemp[i]?.dateInISOString);

            if (currentDate <= webinarDate) {
                upcoming?.push(allWebinarsTemp[i]);
            } else {
                archived?.push(allWebinarsTemp[i]);
            }
        }

        setState({
            upcoming,
            archived
        });
    }
}

const Activities = ({
    showFeatures,
    webinarFeaturesRef
}) => {

    // Static data start...
    // State variables init state start...
    const allWebinarsInitState = {
        upcoming: [],
        archived: []
    }
    // State variables init state start...
    // Static data end...

    // State variables...
    const [allWebinars, setAllWebinars] = useState(allWebinarsInitState);
    const [showWebinarInvitationForm, setShowWebinarInvitationForm] = useState(false);
    const [formState, setFormState] = useState();
    // State variables...



    // All Handlers...

    // State update handlers...
    const updateShowWebinarInvitationFormHandler = (value) => {
        setShowWebinarInvitationForm(value);
    }
    // State update handlers...


    const scrollToWebinarFeaturesHandler = () => {
        console.log(webinarFeaturesRef);
        const scrollTo = webinarFeaturesRef?.current?.getBoundingClientRect()?.top + window.scrollY;
        if (scrollTo) {
            window.scrollTo(0, Math.floor(scrollTo) - 300);
        }
    }


    // All Handlers...


    // Effects start...
    useEffect(() => {
        document.title = "leadership.NET | Activities";
    }, []);


    useEffect(() => {
        const asyncHandler = async () => {
            setAllWebinarsHandler(setAllWebinars);
        }

        asyncHandler();
    }, []);
    // Effects end...

    return (
        <section className="pb-0 text-center">

            <WebinarList 
                webinars={allWebinars?.upcoming}
                title={`Upcoming Webinars`}
                navigateToWebinarDetails={true}
            />

            <WebinarList 
                webinars={allWebinars?.archived}
                title={`Archived Webinars`}
                navigateToWebinarDetails={false}
            />

            {/* <div
                className='box-shadow'
                style={{
                    margin: '0 auto',
                }}
            >
                <h2 className="mb-4">
                    <span class="font-w-4 d-block"
                        style={{
                            color: "var(--leaderbridge-red)"
                        }}
                    >Upcoming</span>
                </h2>
                <div class="">
                    <h3 class={`mb-3`}>
                        <span class="font-w-4">{leaderbridge} Webinar </span> 
                        <span className='webinar-invitation-only-text'>(By Invitation Only)</span>
                    </h3>  
                </div>

                <div class="mb-5">
                    <p class={`mb-1 fs-5`}><span class="font-w-4 d-block">Title: Finding safe sounding boards outside of your company </span></p>  
                    <p className='mb-1 fs-5'>- Discussion of tradeoffs of competency focused engagement</p>
                    <p className='mb-1 fs-5'>- Introduction to {leaderbridge} for focused engagement in problem solving</p>
                    <p class={`mb-1 mt-5 fs-5`}><span class="font-w-4 d-block">Date: Saturday, September 30 </span></p>  
                    <p class={`mb-1 fs-5`}><span class="font-w-4 d-block">Time: 10 AM CDT </span></p>  
                    <p class={`mb-1 fs-5`}><span class="font-w-4 d-block">Duration: Approximately 45 Minutes </span></p>  
                    <p class={`mb-1 fs-5`}><span class="font-w-4 d-block">Platforms: Zoom/LinkedIn (Webinar Link provided upon registration) </span></p>  
                </div>

                <div>
                    <button 
                        className='btn-primary-custom'
                        onClick={() => {
                            updateShowWebinarInvitationFormHandler(true);
                        }}
                        // onClick={scrollToWebinarFeaturesHandler}    
                    >Request Invite</button>
                </div>       
            </div> */}


            {/* {showWebinarInvitationForm ? <>
                <InvitationForm
                    setShowFormState={setShowWebinarInvitationForm}    
                    formState={formState}
                    setFormState={setFormState}
                />
            </> : null} */}
            

        </section>
    )
}

export default Activities;