import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Sidebar = (props) => {
  const [active, setActive] = useState("Scheduled");
  let location = useLocation();
  const handleItemClick = (item) => {
    setActive(item);
  };

  console.log("locationlocation", location);

  return (
    <>
      <header>
        <nav
          id="sidebarMenu"
          className="collapse d-lg-block sidebar collapse bg-white"
        >
          <div className="position-sticky">
            <div className="list-group list-group-flush mx-3 mt-4">
              <NavLink
                to="/dashboard"
                className={`list-group-item list-group-item-action py-2 ripple ${
                  location?.pathname === "/dashboard" ? "active" : ""
                }`}
                aria-current="true"
                // onClick={() => handleItemClick("Scheduled")}
              >
                <i className="fas fa-tachometer-alt fa-fw me-3"></i>
                <span>Scheduled</span>
              </NavLink>
              <a>
                <NavLink
                  to="/unscheduled"
                  className={`list-group-item list-group-item-action py-2 ripple ${
                    location?.pathname === "/unscheduled" ? "active" : ""
                  }`}
                  // onClick={() => handleItemClick("Unscheduled")}
                >
                  <i className="fas fa-chart-area fa-fw me-3"></i>
                  <span>Unscheduled</span>
                </NavLink>
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Sidebar;
