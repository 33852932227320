import React from "react";
import LN_Logo from "../../../src/images/lN_LOGO.png";
import "./Ln_logo.scss";
function Ln_logo() {
  return (
    <div className="brand-logo">
      <img src={LN_Logo} alt="Logo" />
      <span className="leader-ship">
        <span className="leader">leadership</span>
        <span className="ship">.NET</span>
      </span>
      <span className="trademark">™</span>
    </div>
  );
}

export default Ln_logo;
