// const protocol = "https"; //live
const host = "api.leadership.net"; // for production
const hostLeaderBridge = "api.leaderbridge.com";
const hostStagingLeaderBridge = "leadership-backend.onrender.com"; // for production live

const Verification = "app.fully-verified.com";
// https://sandbox.fully-verified.com
const protocol = "https"; //live

const protocolDev = "http";

// const host = "api.leadership.rejoicehub.com"; // for development  live
const hostDev = "localhost:8000";
const hostLeaderBridgeDev = "localhost:8000";

// const protocol = "http"; //live
// const host = "192.168.29.228:8088"; // for local live

// const protocol = "http"; //live
// const host = "localhost:8088"; // for local live
const hostUrl = `${protocol}://${host}/api/v1/`;
const FullyVerifed = `https://${Verification}/`;
const hostUrlForSocket = `${protocol}://${host}/`;

const hostUrlLeaderBridge = `${protocol}://${host}/api/v1/`;
const hostUrlForSocketLeaderBridge = `${protocol}://${hostLeaderBridge}/`;

export const API = {
  protocol,
  host,
  hostUrl,
  FullyVerifed: FullyVerifed,
  hostUrlForSocket: hostUrlForSocket,
  hostUrlLeaderBridge,
};
