import React from "react";
import PropTypes from "prop-types";
import "./MeetingItem.scss";

const MeetingItem = ({ meeting }) => {
  const {
    name,
    start_time,
    end_time,
    status,
    event_memberships,
    event_guests,
    invitees_counter,
    location,
  } = meeting?.meeting?.resource;

  const replaceDomain = (url) => {
    const segments = url.split("/");
    const dynamicPath = segments.slice(-2).join("/"); // Get the last two segments and join them with '/'
    const baseUrl = "https://calendly.com/john-w8vp/accelerator-meeting/";
    const fullUrl = baseUrl + dynamicPath;

    return fullUrl;
  };

  const startTime = new Date(start_time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = new Date(end_time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const startDate = new Date(start_time).toLocaleDateString([], {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const ownerName = event_memberships[0]?.user_name || "N/A";
  const guestEmails = event_guests.map((guest) => guest.email).join(", ");
  const activeInvitees = invitees_counter.active;
  const totalInvitees = invitees_counter.total;

  const handleJoinMeeting = () => {
    window.open(location?.join_url, "_blank");
  };

  return (
    <div className="meeting-item">
      <div className="header">
        <h3 className="title">{name}</h3>
        <p className={`status ${status.toLowerCase()}`}>
          {status.toUpperCase()}
        </p>
      </div>
      <div className="time-section">
        <div className="time-info">
          <p className="label">Start</p>
          <p className="time">{`${startDate}, ${startTime}`}</p>
        </div>
        <div className="time-info">
          <p className="label">End</p>
          <p className="time">{`${startDate}, ${endTime}`}</p>
        </div>
      </div>
      <div className="details">
        <p className="owner">Owner: {ownerName}</p>
        {location?.type === "microsoft_teams_conference" && (
          <button className="join-button" onClick={handleJoinMeeting}>
            Join Meeting
          </button>
        )}
      </div>
    </div>
  );
};

MeetingItem.propTypes = {
  meeting: PropTypes.shape({
    resource: PropTypes.shape({
      name: PropTypes.string.isRequired,
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      event_memberships: PropTypes.arrayOf(
        PropTypes.shape({
          user_name: PropTypes.string.isRequired,
        })
      ).isRequired,
      event_guests: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
        })
      ).isRequired,
      invitees_counter: PropTypes.shape({
        active: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }).isRequired,
      location: PropTypes.shape({
        type: PropTypes.string,
        join_url: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default MeetingItem;
